function getPriceImage(code: string): string {
  switch (code) {
    case 'AK01':
      return require('@/assets/pictures/prizes/01.png');
    case 'AK02':
      return require('@/assets/pictures/prizes/02.png');
    case 'AK03':
      return require('@/assets/pictures/prizes/03.png');
    case 'AK04':
      return require('@/assets/pictures/prizes/04.png');
    case 'AK05':
      return require('@/assets/pictures/prizes/05.png');
    case 'AK06':
      return require('@/assets/pictures/prizes/06.png');
    case 'AK07':
      return require('@/assets/pictures/prizes/07.png');
    case 'AK08':
      return require('@/assets/pictures/prizes/08.png');
    case 'AK09':
      return require('@/assets/pictures/prizes/09.png');
    case 'AK10':
      return require('@/assets/pictures/prizes/10.png');
    case 'AK11':
      return require('@/assets/pictures/prizes/11.png');
    case 'AK12':
      return require('@/assets/pictures/prizes/12.png');
    case 'AK13':
      return require('@/assets/pictures/prizes/13.png');
    case 'AK14':
      return require('@/assets/pictures/prizes/14.png');
    case 'AK15':
      return require('@/assets/pictures/prizes/15.png');
    case 'AK16':
      return require('@/assets/pictures/prizes/16.png');
    case 'AK17':
      return require('@/assets/pictures/prizes/17.png');
    case 'AK18':
      return require('@/assets/pictures/prizes/18.png');
    case 'AK19':
      return require('@/assets/pictures/prizes/19.png');
    case 'AK20':
      return require('@/assets/pictures/prizes/20.png');
    case 'AK21':
      return require('@/assets/pictures/prizes/21.png');
    case 'AK22':
      return require('@/assets/pictures/prizes/22.png');
    case 'AK23':
      return require('@/assets/pictures/prizes/23.png');
    case 'AK24':
      return require('@/assets/pictures/prizes/24.png');
    case 'main':
      return require('@/assets/pictures/prizes/main.png');
    default:
      return require('@/assets/pictures/gift_placeholder.svg');
  }
}

export { getPriceImage };
