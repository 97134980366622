import { easterTexts2020 } from '@/texts/theme-specific/easter-texts-2020';
import { adventTexts2021 } from '@/texts/theme-specific/advent-texts-2021';
import { easterTexts2022 } from '@/texts/theme-specific/easter-texts-2022';
import { adventTexts2022 } from '@/texts/theme-specific/advent-texts-2022';
import { TextScheme } from '@/models/Texts';
import { easterTexts2023 } from '@/texts/theme-specific/easter-texts-2023';
import { adventTexts2023 } from './theme-specific/advent-texts-2023';

interface ThemeTexts {
  // have to define indexing type for themeTexts to access via identifier
  [key: string]: TextScheme;
}

export const themeTexts: ThemeTexts = {
  // key is to be used in 'textsIdentifier' field of the corresponding theme
  easter2020: easterTexts2020,
  advent2021: adventTexts2021,
  easter2022: easterTexts2022,
  advent2022: adventTexts2022,
  easter2023: easterTexts2023,
  advent2023: adventTexts2023
};
