
import { defineComponent } from 'vue';
import InputField from '@/components/InputField.vue';
import CheckboxWithText from '@/components/CheckboxWithText.vue';
import BigButton from '@/components/BigButton.vue';
import axios from 'axios';
import createStore from '@/store/index';
import SubtitleText from '@/components/SubtitleText.vue';
import { Prize } from '@/models/Prize';
import { adventTexts2023 } from '@/texts/theme-specific/advent-texts-2023';

export default defineComponent({
  name: 'RaffleForm',
  components: {
    InputField,
    CheckboxWithText,
    BigButton
  },
  props: {
    subtitle: String,
    won: Boolean
  },
  data() {
    return {
      required: true,
      firstName: '',
      lastName: '',
      gender: 'herr',
      email: '',
      newsletter: true,
      texts: adventTexts2023,
      mainRaffleDateInfo: adventTexts2023.infoTexts.mainRaffleDate,
      firstNamePlaceholder: adventTexts2023.formLabels.firstNamePlaceholder,
      lastNamePlaceholder: adventTexts2023.formLabels.lastNamePlaceholder,
      emailPlaceholder: adventTexts2023.formLabels.emailPlaceholder,
      buttonText: adventTexts2023.formLabels.buttonText,
      checkboxLabel: adventTexts2023.formLabels.checkboxLabel,
      firstNameError: '',
      lastNameError: '',
      emailError: '',
      emailPattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      isFirstNameValid: false,
      isLastNameValid: false,
      isEmailValid: false
    };
  },
  computed: {
    prizeId(): string | null | undefined {
      return this.myPrize?.id ? this.myPrize.id : '-';
    },
    prizeDescription(): string | null | undefined {
      return this.myPrize?.description ? this.myPrize.description : '-';
    },
    myPrize(): Prize | undefined | null {
      return createStore.state.prize;
    }
  },
  methods: {
    firstNameInput(value: string) {
      this.firstName = value;
      this.isFirstNameValid = this.checkNameValidity(value);
    },
    lastNameInput(value: string) {
      this.lastName = value;
      this.isLastNameValid = this.checkNameValidity(value);
    },
    checkboxInput(value: boolean) {
      this.newsletter = value;
    },
    emailInput(value: string) {
      this.email = value;
      this.isEmailValid = this.checkEmailValidity(value);
    },
    checkNameValidity(value: string): boolean {
      return !!value.trim();
    },
    checkEmailValidity(value: string): boolean {
      return this.emailPattern.test(value);
    },
    isValid(): boolean {
      this.firstNameError = '';
      this.emailError = '';
      this.lastNameError = '';
      if (!this.firstName.trim()) {
        this.firstNameError = this.texts.errorMessages.invalidFirstName;
      }
      if (!this.lastName.trim()) {
        this.lastNameError = this.texts.errorMessages.invalidLastName;
      }
      if (!this.emailPattern.test(this.email)) {
        this.emailError = this.texts.errorMessages.invalidEmail;
      }
      if (this.firstNameError === '' && this.emailError === '' && this.lastNameError === '') {
        return true;
      }
      return false;
    },
    async send() {
      const form = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        newsletter: this.newsletter,
        prizeId: this.prizeId,
        prizeDescription: this.prizeDescription,
        gender: this.gender
      };
      if (this.isValid()) {
        try {
          await axios.put(`${process.env.VUE_APP_BASE_ENDPOINT}/contact`, form);
          await createStore.dispatch('setHasJoinedMainRaffle', true);
        } catch (error) {
          console.log('error', error);
        }
      }
      this.$emit('send');
    }
  }
});
