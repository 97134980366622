import { TextScheme } from '@/models/Texts';
import {
  getDefaultTitles,
  getDefaultSubTitles,
  getDefaultTitleTexts,
  getDefaultTitleWithImageTexts,
  getDefaultImprint,
  getDefaultInfoTexts,
  getDefaultFormLabels,
  getDefaultButtonLabels,
  getDefaultErrorMessages,
  getDefaultCouponTexts
} from '@/texts/textService';

/*
Since default texts are based on those of this particular event, it will simply
return the defaults everywhere
 */

export const easterTexts2020: TextScheme = {
  titles: getDefaultTitles(),
  subTitles: getDefaultSubTitles(),
  titleTexts: getDefaultTitleTexts(),
  titleWithImageTexts: getDefaultTitleWithImageTexts(),
  imprint: getDefaultImprint(),
  infoTexts: getDefaultInfoTexts(),
  formLabels: getDefaultFormLabels(),
  buttons: getDefaultButtonLabels(),
  errorMessages: getDefaultErrorMessages(),
  couponTexts: getDefaultCouponTexts()
};
