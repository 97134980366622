
import { defineComponent } from 'vue';
import axios from 'axios';
import createStore from '@/store/index';
import { Prize } from '@/models/Prize';
import { colors } from '@/configuration/themes/advent-theme-2023';

export default defineComponent({
  name: 'Base',
  components: {},
  data() {
    return {
      hasTriedToday: false,
      prize: undefined
    };
  },
  methods: {
    async getPrize() {
      const expires = new Date();
      expires.setHours(23, 59, 59, 999);
      const prize = await axios.get(`${process.env.VUE_APP_BASE_ENDPOINT}/prizewon`, {
        headers: {
          'Set-cookies': document.cookie
        }
      });
      await createStore.dispatch('setPrize', prize.data);
      document.cookie = `hasTriedToday=true; expires = ${expires} ;path=/`;
      this.hasTriedToday = true;
    }
  },
  created() {
    document.cookie === 'hasTriedToday=true' ? (this.hasTriedToday = true) : (this.hasTriedToday = false);
  },
  computed: {
    myPrize(): Prize | undefined {
      return createStore.state.prize;
    },
    themeExpired(): boolean {
      return createStore.state.themeExpired;
    },
    baseBackgroundStyle(): object {
      /*
       we only set to color once this.themeExpired has evaluated to true, prevents
       flickering of color->white when theme is actually expired
       */
      return !this.themeExpired ? { backgroundColor: 'transparent' } : { backgroundColor: colors.WHITE };
    }
  }
});
