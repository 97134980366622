/*
Contains "default" texts.

This is based on the Ostergame 2020 (I actually think it was in 2021 but I will not
refactor that everywhere lol). Nonetheless most texts are generic and will work for most
events/"themes".
 */

const titles = {
  landingView: 'Tippen Sie auf die Eier zum spielen',
  won: 'Herzliche Gratulation!',
  lost: 'Leider nicht gewonnen',
  participated: 'Sie haben heute bereits teilgenommen',
  defaultError: 'Hoppla',
  expired: 'Auf Wiedersehen',
  themeExpired: 'Aktuell findet kein Spiel statt.'
};

const subTitles = {
  redeem: 'Einlösen',
  mainRaffle: 'Hauptverlosung'
};

const titleTexts = {
  won: 'Ihr Gewinn:',
  lost: 'Versuchen Sie es doch morgen wieder, es warten weiterhin tolle Preise auf Sie.',
  participated: 'Versuchen Sie es doch morgen wieder, es warten weiterhin tolle Preise auf Sie.',
  hasJoinedMainRaffle:
    'Vielen Dank für Ihre Teilnahme an der Hauptverlosung. Die Ziehung der Gewinnerin oder des Gewinners findet zwischen 28. und 31.12.2020 statt.',
  titleIntro: 'Heutiger Tagespreis:'
};
const titleWithImageTexts = {
  participated: 'Hauptverlosung',
  mainPrize: `Einkaufsgutschein von LOEB im Wert von CHF 1'000.-`
};
const imprint = {
  title: `<h3>Impressum</h3>`,
  editing: `<p><span class="bold-span">Redaktion / Gesamtverantwortung</span>
   <br>Loeb AG
   <br>Spitalgasse 47-51
   <br>3001 Bern
   <br>Telefon: <a href="tel:0313207111">031 320 71 11</a>
   <br>E-Mail: <a href="mailto:info@loeb.ch">info@loeb.ch</a>
   </p>`,
  design: `<p><span class="bold-span">Web-Programmierung</span>
    <br />jls digital ag
    <br />Libellenrain 17
    <br />6004 Luzern
    </p>`,
  legal: `<p> <span class="bold-span">Rechtliches</span>
    <br /> Zur Teilnahme berechtigt sind alle Personen mit Wohnsitz in der Schweiz. Davon ausgenommen sind Mitarbeitende der LOEB AG und deren Mietpartner. Die Gewinner:innen werden schriftlich benachrichtigt.
Über den Wettbewerb wird keine Korrespondenz geführt. Eine Barauszahlung ist nicht möglich. Der Rechtsweg ist ausgeschlossen. Die Teilnahme an der Verlosung erfolgt unabhängig von einer Produktbestellung bzw. einem Kauf.
    <br /><br />Das Copyright für den gesamten Internetauftritt liegt bei der Loeb AG. Inhalte dürfen nicht in einem anderen Zusammenhang verwendet oder kopiert werden. Für Informationen und Inhalte auf dieser Webseite werden sämtliche Haftungsansprüche ausgeschlossen.
    Persönliche Daten, die beim Ausfüllen des Formulars übermittelt werden, sind vertraulich und werden nicht an Dritte weitergegeben.
    </p>`,
  copyright: `<p><span class="bold-span">© 2023 Loeb AG</span>
</p>`
};
const infoTexts = {
  won: `<p>Wir senden Ihnen Ihren Gewinn per Post zu. Wir werden Sie kontaktieren.
          Bitte füllen Sie Ihre Angaben aus. Sie nehmen automatisch an der Verlosung vom Hauptgewinn teil.
        </p>`,
  mainRaffle: `
  <p>
    Um an der Verlosung des Hauptgewinns teilzunehmen, hinterlassen Sie uns doch Ihre E-Mail-Adresse.
  </p>`,
  hasJoinedMainRaffle: `<p>Vielen Dank für Ihre Teilnahme an der Hauptverlosung.</p>`,
  hasJoinedMainRaffleAfterWin: `<p>Besten Dank für Ihre Koordinaten.
Wir werden zeitnahe mit Ihnen Kontakt aufnehmen um Ihnen den Preis zusenden zu können.</p>`,
  mainRaffleDate: `<p>
  Die Ziehung der Gewinnerin oder des Gewinners findet zwischen dem 06.04 und 09.04.2021 statt.
</p>`
};
const couponTexts = {
  validUntil: 'Gültig bis am 31.12.2020'
};
const formLabels = {
  firstNamePlaceholder: 'Vorname',
  lastNamePlaceholder: 'Name',
  emailPlaceholder: 'E-Mail-Adresse',
  buttonText: 'Senden',
  checkboxLabel: 'Ich möchte den Newsletter von LOEB erhalten.'
};
const errorMessages = {
  locationDisabled: `Leider können Sie ohne das Freigeben des Zugangs auf Ihren Standort nicht mitspielen.`,
  invalidFirstName: 'Das Feld „Vorname“ muss ausgefüllt sein.',
  invalidLastName: 'Das Feld „Name“ muss ausgefüllt sein.',
  invalidEmail: 'Ungültige E-Mail Adresse.',
  farFromWindow: 'Stehen Sie vor das Schaufenster am Loebegge in Bern und versuchen sie es erneut.',
  expired: 'Das LOEB Adventgame ist leider vorbei. Wir freuen uns bereits jetzt aufs nächste Jahr.',
  themeExpired: 'Wir informieren gerne wieder, sobald ein neues LOEB Game ansteht.',
  locationInfo: 'Die Standortdaten werden gebraucht um das Spiel vor Ort am Schaufenster spielen zu können.',
  locationInstructions: `<ol>
  <li>Gehen Sie in die <strong>Einstellungen</strong></li>
  <li>Wählen Sie <strong>Datenschutz</strong></li>
  <li>Wählen Sie <strong>Ortungsdienste</strong></li>
  <li>Wählen Sie den Browser</li>
  <li>Klicken Sie auf <strong>Standort erlauben</strong> und aktivieren Sie <strong>Genauer Standort</strong></li>
  </ol>`
};
const buttons = {
  continue: 'Weiter',
  stayInformed: 'Auf dem Laufenden bleiben'
};

export {
  titles,
  titleTexts,
  buttons,
  imprint,
  infoTexts,
  formLabels,
  errorMessages,
  couponTexts,
  titleWithImageTexts,
  subTitles
};
