
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'InputField',
  props: {
    required: {
      type: Boolean,
      default: false
    },
    placeholder: String,
    type: String,
    errorMessage: String,
    maxLength: {
      type: Number,
      default: 50
    }
  },
  data() {
    return {
      inputElement: { type: Object as () => HTMLInputElement | null },
      value: ''
    };
  },
  computed: {
    isValid(): boolean {
      // errorMessage(s) initialized with empty string in RaffleForm
      return this.errorMessage === '';
    }
    // // styles - have to be inline to work with the way themes are set up
    // inputStyle(): object {
    //   const color = this.isValid
    //     ? this.currentTheme.fontColors.textOnFooterAssetColor
    //     : this.currentTheme.fontColors.errorTextColor;
    //   const borderColor = this.isValid
    //     ? this.currentTheme.fontColors.textOnFooterAssetColor
    //     : this.currentTheme.fontColors.errorTextColor;
    //   return {
    //     // CANNOT inline style placeholder font color, see '/styles/theme.module.scss' for that
    //     backgroundColor: this.currentTheme.assetColors.footerAssetColor,
    //     color: color,
    //     border: `1px solid ${borderColor}`
    //   };
    // },
    // errorTextStyle(): object {
    //   return {
    //     color: this.currentTheme.fontColors.errorTextColor
    //   };
    // }
  },
  methods: {
    emitBlur() {
      this.$emit('blur', this.value);
    },
    validate(value: string): boolean {
      const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailPattern.test(value);
    }
  }
});
