/*
provides functions to fetch default texts for a given interface.
furthermore, returns texts of the currently applied theme
 */

import {
  titles,
  titleTexts,
  buttons,
  imprint,
  infoTexts,
  formLabels,
  errorMessages,
  couponTexts,
  titleWithImageTexts,
  subTitles
} from '@/texts/default/default-texts';
import {
  TextScheme,
  Titles,
  SubTitles,
  TitleTexts,
  TitleWithImageTexts,
  Imprint,
  InfoTexts,
  CouponTexts,
  FormLabels,
  Buttons,
  ErrorMessages
} from '@/models/Texts';
import { themeTexts } from '@/texts/texts';

function getDefaultTitles(): Titles {
  return titles;
}

function getDefaultSubTitles(): SubTitles {
  return subTitles;
}

function getDefaultTitleTexts(): TitleTexts {
  return titleTexts;
}

function getDefaultTitleWithImageTexts(): TitleWithImageTexts {
  return titleWithImageTexts;
}

function getDefaultImprint(): Imprint {
  return imprint;
}

function getDefaultInfoTexts(): InfoTexts {
  return infoTexts;
}

function getDefaultFormLabels(): FormLabels {
  return formLabels;
}

function getDefaultButtonLabels(): Buttons {
  return buttons;
}

function getDefaultErrorMessages(): ErrorMessages {
  return errorMessages;
}

function getDefaultCouponTexts(): CouponTexts {
  return couponTexts;
}

function getTextsForTheme(identifier: string): TextScheme {
  return themeTexts[identifier];
  // return themeTexts[currentTheme.textsIdentifier];
}

// todo: update for current event
function getCurrentTexts(): TextScheme {
  return themeTexts.advent2023;
}

export {
  getDefaultTitles,
  getDefaultSubTitles,
  getDefaultTitleTexts,
  getDefaultTitleWithImageTexts,
  getDefaultImprint,
  getDefaultInfoTexts,
  getDefaultFormLabels,
  getDefaultButtonLabels,
  getDefaultErrorMessages,
  getDefaultCouponTexts,
  getTextsForTheme,
  getCurrentTexts
};
