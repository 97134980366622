<template>
  <div class="title" :style="titleStyle">
    <h1>
      {{ title }}
    </h1>
    <p class="primary-header" v-if="primaryHeaderParagraph" :style="textStyle">{{ primaryHeaderParagraph }}</p>
    <p :class="{ 'has-price': myPrize }" :style="textStyle">{{ titleText }}</p>
    <p v-if="myPrize" :style="textStyle">{{ myPrize.description }}</p>
    <div v-if="showImage && hasImage" class="image" :style="{ backgroundImage: `url('${prizeImage}')` }"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Prize } from '@/models/Prize';

export default defineComponent({
  name: 'Title',
  props: {
    title: String,
    titleText: String,
    primaryHeaderParagraph: String,
    fade: {
      type: Boolean,
      default: false
    },
    prizeImage: {
      // expects input of form require('@/assets/pictures/somePic.svg'), so import
      // already resolved by webpack
      type: String,
      default: ''
    },
    showImage: {
      type: Boolean,
      default: false
    },
    myPrize: { type: Object as () => Prize }
  },
  data() {
    return {};
  },
  computed: {
    // TODO: these styles can be done just with dynamic css classes
    titleStyle(): object {
      if (this.fade) {
        return {
          color: 'transparent',
          transition: 'all 0.8s ease-out'
        };
      }
      return {
        color: '#0A123D'
      };
    },
    textStyle(): object {
      if (this.fade) {
        return {
          color: 'transparent',
          transition: 'all 0.8s ease-out'
        };
      }
      return {
        color: '#0A123D'
      };
    },
    hasImage(): boolean {
      return !this.prizeImage || this.prizeImage !== '';
    }
  }
});
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
@import '~@/styles/theme.module.scss';

.title {
  text-align: center;
  h1 {
  }
  .primary-header {
    margin-bottom: 0;
  }
  p {
    &.has-price {
      margin-bottom: 10;
    }
  }
  .image {
    width: rem(180px);
    height: rem(180px);
    background-repeat: no-repeat;
    background-size: contain;
    margin: rem(20px) auto;
    background-position: center;
  }
}
</style>
