
import { defineComponent } from 'vue';
import axios from 'axios';
import { format } from 'date-fns';
import createStore from '@/store/index';
import Title from '@/components/Title.vue';
import { Participated } from '@/models/general';
import { Prize } from '@/models/Prize';
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue';
import lottie from 'lottie-web';
import { adventTexts2023 } from '@/texts/theme-specific/advent-texts-2023';

export default defineComponent({
  name: 'LandingView',
  components: {
    Title,
    LottieAnimation
  },
  data() {
    return {
      day: format(new Date(), 'd'),
      title: adventTexts2023.titles.landingView,
      won: false,
      wonRequestHasBeenSent: false,
      titleIntro: adventTexts2023.titleTexts.titleIntro
    };
  },
  methods: {
    async goToResults() {
      this.$router.push({ name: 'ResultView' });
    },
    async getPrize() {
      try {
        const prize = await axios.get(`${process.env.VUE_APP_BASE_ENDPOINT}/prize/won`, {
          withCredentials: true
        });
        await createStore.dispatch('setPrize', prize.data);
        await createStore.dispatch('setParticipated', this.myPrize?.id ? Participated.WON : Participated.LOST);
      } catch (error) {
        if (error.response.status === 403) {
          await createStore.dispatch('setParticipated', Participated.PARTICIPATED_TODAY);
        }
        throw error;
      }
    },
    async handleClick() {
      if (!this.wonRequestHasBeenSent) {
        this.wonRequestHasBeenSent = true;
        // have to prevent further clicks/calls after the first one is initialized, else navigation
        // MAY break (double navigation, inconsistent participated state)
        await createStore.commit('setFadeOnAnimationHasStarted', true);
        await this.getPrize();
        this.won = createStore.state.participated === Participated.WON;

        setTimeout(() => {
          this.playAnimation();
        }, 100); // -.1s off the css transition time, to ensure no gap between scale and animation
      }
    },
    playAnimation() {
      lottie.play();
      setTimeout(() => {
        this.initiateTransition();
        setTimeout(() => {
          this.goToResults();
        }, 1000);
      }, 5500);
    },
    setLottieHeight() {
      const container = document.querySelector('#lottie-container') as HTMLElement;
      if (!container) {
        return;
      }
      const coordinates = container.getBoundingClientRect();
      const footerMargin = 100;
      const windowHeight = document.documentElement.clientHeight;
      const availableHeight = windowHeight - coordinates.top - footerMargin;
      container.style.height = `${availableHeight}px`;
    },
    async initiateTransition() {
      await createStore.dispatch('setShowOverlay', true);
    }
  },
  async created() {
    this.setLottieHeight();
    if (document.cookie.split(';').some(item => item.includes('participated=true'))) {
      await createStore.dispatch('setParticipated', Participated.PARTICIPATED_TODAY);
      this.$router.push({ name: 'ResultView' });
    }
    window.addEventListener('resize', () => {
      setTimeout(() => {
        this.setLottieHeight();
      }, 200);
    });
  },
  mounted() {
    this.setLottieHeight();
  },
  computed: {
    dayLabelX(): number {
      // TODO: A perfect solution would require a lot of individual values for specific days...
      // Doing the destinction between one and two digits is the bare minimum.
      return +this.day < 10 ? 55.864 : 50;
    },
    myPrize(): Prize | undefined | null {
      return createStore.state.prize;
    },
    fadeTitle(): boolean {
      return createStore.state.fadeOnAnimationHasStarted;
    },
    themeExpired(): boolean {
      return createStore.state.themeExpired;
    },
    hasWon(): boolean {
      return createStore.state.participated === Participated.WON;
    },
    hasLost(): boolean {
      return createStore.state.participated === Participated.LOST;
    },
    prizeDescription(): undefined | string {
      return createStore.state.prizeDescription;
    },
    titleText(): string {
      if (this.prizeDescription) {
        return `${this.prizeDescription}`;
      }
      return '';
    },
    primaryHeaderText(): string | undefined {
      if (this.prizeDescription) {
        return 'Tagesgewinn:';
      }
      return undefined;
    },
    videoDesktopWinSrc(): string {
      return require('@/assets/movies/Desktop_win.webm');
    },
    videoDesktopLossSrc(): string {
      return require('@/assets/movies/Desktop_loss.webm');
    }
  }
});
