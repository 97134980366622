import { TextScheme } from '@/models/Texts';
import {
  getDefaultButtonLabels,
  getDefaultCouponTexts,
  getDefaultErrorMessages,
  getDefaultFormLabels,
  getDefaultImprint,
  getDefaultInfoTexts,
  getDefaultSubTitles,
  getDefaultTitles,
  getDefaultTitleTexts,
  getDefaultTitleWithImageTexts
} from '@/texts/textService';

export const adventTexts2023: TextScheme = {
  titles: {
    ...getDefaultTitles(),
    landingView: 'Klicken Sie um zu Spielen'
  },
  subTitles: getDefaultSubTitles(),
  titleTexts: {
    ...getDefaultTitleTexts(),
    hasJoinedMainRaffle:
      'Vielen Dank für Ihre Teilnahme an der Hauptverlosung. Die Ziehung der Gewinnerin oder des Gewinners findet zwischen dem 25. und 29. Dezember 2023 statt.'
  },
  titleWithImageTexts: {
    ...getDefaultTitleWithImageTexts(),
    mainPrize: `Einkaufsgutschein von LOEB im Wert von CHF 1'000.-`
  },
  imprint: {
    ...getDefaultImprint(),
    copyright: `<p><span class="bold-span">© 2023 Loeb AG</span>
    </p>`
  },
  infoTexts: {
    ...getDefaultInfoTexts(),
    mainRaffleDate: `<p>
    Die Ziehung der Gewinnerin oder des Gewinners findet zwischen dem 25. und 29. Dezember 2023 statt.
    </p>`,
    won: `<p>Bitte hinterlegen Sie Ihre Angaben, damit wir Sie kontaktieren können. Den Gewinn senden wir Ihnen gerne per Post zu.
Sie nehmen automatisch an der Verlosung vom Hauptgewinn teil.</p>`
  },
  formLabels: getDefaultFormLabels(),
  buttons: getDefaultButtonLabels(),
  errorMessages: {
    ...getDefaultErrorMessages(),
    expired: 'Das LOEB Adventgame ist leider vorbei. Wir freuen uns bereits jetzt aufs nächste Jahr.'
  },
  couponTexts: getDefaultCouponTexts()
};
