<template>
  <div class="result-view">
    <div id="scrollContainer">
      <div class="prize-details">
        <div class="background">
          <div class="background-inner">
            <Title
              :title="title"
              :titleText="titleText"
              :myPrize="myPrize"
              :showImage="wonPrizeShowImage"
              :prizeImage="currentPriceImage"
              :class="{ lost: participated !== 'won' }"
            ></Title>
            <TitleWithImage
              class="raffle-description"
              v-if="participated !== 'won'"
              :title="titleWithImageTitle"
              :showImage="mainPrizeShowImage"
              :titleText="mainPrizeText"
            ></TitleWithImage>
          </div>
        </div>
      </div>

      <div class="other-details" id="scrollTarget">
        <div class="other-details-content">
          <!--joined MainRaffle-->
          <div v-if="hasJoinedMainRaffle">
            <!--won-->
            <div v-if="participated === 'won'">
              <div class="info-text" v-html="hasJoinedMainRaffleInfoAfterWin"></div>
              <SubtitleText :subtitle="subtitle2" :varContents="[mainRaffleDateInfo]"> </SubtitleText>
            </div>
            <!--else-->
            <div v-else>
              <SubtitleText :subtitle="subtitle2" :varContents="[hasJoinedMainRaffleInfo, mainRaffleDateInfo]">
              </SubtitleText>
            </div>
          </div>
          <!--not joined MainRaffle-->
          <div v-else>
            <!--won-->
            <div v-if="participated === 'won'">
              <SubtitleText :subtitle="subtitle1" :varContents="[wonInfo]"> </SubtitleText>
            </div>
            <!--else-->
            <div v-else>
              <SubtitleText :subtitle="subtitle2" :varContents="[mainRaffleInfo, mainRaffleDateInfo]"> </SubtitleText>
            </div>
          </div>

          <RaffleForm
            v-if="!hasJoinedMainRaffle"
            :won="participated === 'won'"
            :subtitle="subtitle2"
            @send="scaleFooterColorCover"
          ></RaffleForm>
        </div>
      </div>

      <div @click="moveDown" class="icon-container" v-if="showScrollIcon">
        <div class="chevron-down" :style="chevronStyle"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import createStore from '@/store/index';
import { Prize } from '@/models/Prize';
import Title from '@/components/Title.vue';
import TitleWithImage from '@/components/TitleWithImage.vue';
import SubtitleText from '@/components/SubtitleText.vue';
import RaffleForm from '@/components/RaffleForm.vue';
import { Participated } from '@/models/general';
import { getPriceImage } from '@/services/imageResolverService';
import { getCurrentTexts } from '@/texts/textService';

export default defineComponent({
  name: 'ResultsView',
  components: {
    Title,
    TitleWithImage,
    SubtitleText,
    RaffleForm
  },
  data() {
    return {
      hasTriedToday: false,
      texts: getCurrentTexts(),
      subtitle1: getCurrentTexts().subTitles.redeem,
      subtitle2: getCurrentTexts().subTitles.mainRaffle,
      participatedEnum: Participated,
      wonInfo: getCurrentTexts().infoTexts.won,
      mainRaffleInfo: getCurrentTexts().infoTexts.mainRaffle,
      mainRaffleDateInfo: getCurrentTexts().infoTexts.mainRaffleDate,
      titleWithImageTitle: getCurrentTexts().titleWithImageTexts.participated,
      mainPrizeText: getCurrentTexts().titleWithImageTexts.mainPrize,
      hasJoinedMainRaffleInfo: getCurrentTexts().infoTexts.hasJoinedMainRaffle,
      hasJoinedMainRaffleInfoAfterWin: getCurrentTexts().infoTexts.hasJoinedMainRaffleAfterWin,

      showScrollIcon: true
    };
  },
  watch: {
    hasJoinedMainRaffle(newVal) {
      if (newVal) {
        console.log('scaling');
        this.scaleAssets();
      }
    }
  },
  methods: {
    moveDown(): void {
      document.getElementById('scrollTarget')?.scrollIntoView({ behavior: 'smooth' });
      this.showScrollIcon = false;
    },
    scrollHandler(): void {
      setTimeout(() => {
        if (this.showScrollIcon) {
          this.showScrollIcon = false;
        }
      }, 300);
    },
    scaleAssets() {
      this.scaleDesktopAsset();
      this.scaleFooterColorCover();
    },
    scaleDesktopAsset() {
      // grab logo position
      const logoElement = document.querySelector('#logo') as HTMLElement;
      if (!logoElement) {
        return;
      }
      const logoCoordinates = logoElement.getBoundingClientRect();
      // grab start of 'other-details'
      const otherDetailsElement = document.querySelector('.other-details') as HTMLElement;
      if (!otherDetailsElement) {
        return;
      }
      const otherDetailsCoordinates = otherDetailsElement.getBoundingClientRect();
      // height of the asset is space between otherDetails and logo
      const assetHeight = `${otherDetailsCoordinates.top - logoCoordinates.bottom}px`;
      // grab assetcontainer and set height
      const assetContainer = document.querySelector('#asset-container-desktop') as HTMLElement;
      if (assetContainer) {
        assetContainer.style.height = assetHeight;
      }
    },
    scaleFooterColorCover() {
      // grab other Details Element (since we want to cover potential gaps between it and the bottom of the page
      const otherDetailsElement = document.querySelector('.other-details') as HTMLElement;
      if (!otherDetailsElement) {
        return;
      }
      const otherDetailsCoordinates = otherDetailsElement.getBoundingClientRect();
      // grab available screen height
      const baseElement = document.querySelector('#base') as HTMLElement;
      if (!baseElement) {
        return;
      }
      const baseCoordinates = baseElement.getBoundingClientRect();
      const availableHeight = baseCoordinates.height;
      const someMargin = 30;
      // calculate height so it leaves no gap but also does not go higher than the start of the 'other details' section
      const calculatedHeight = availableHeight - otherDetailsCoordinates.top - someMargin;
      const min = 150;
      const coverHeight = `${Math.max(min, calculatedHeight)}px`;

      const footerColorCoverElement = document.querySelector('.footer-color-cover') as HTMLElement;
      if (footerColorCoverElement) {
        footerColorCoverElement.style.height = coverHeight;
      }
    }
  },
  async created() {
    document.getElementById('app')?.addEventListener('scroll', this.scrollHandler);
    window.addEventListener('resize', () => {
      setTimeout(() => {
        this.scaleAssets();
      }, 200);
    });

    await createStore.dispatch('setShowOverlay', false);
    if (this.hasParticipated) {
      await createStore.dispatch('setParticipated', Participated.PARTICIPATED_TODAY);
    }
    if (this.participated === Participated.NOT_PARTICIPATED) {
      this.$router.push({ name: 'LandingView' });
    }
  },
  mounted() {
    this.scaleAssets();
  },
  beforeUnmount() {
    document.getElementById('app')?.removeEventListener('scroll', this.scrollHandler);
  },
  computed: {
    myPrize(): Prize | undefined {
      return createStore.state.prize;
    },
    participated(): Participated {
      return createStore.state.participated;
    },
    wonPrizeShowImage(): boolean {
      return this.participated === Participated.WON;
    },
    mainPrizeShowImage(): boolean {
      // show main price image if participated but not won
      return this.participated === Participated.PARTICIPATED_TODAY || this.participated === Participated.LOST;
    },
    currentPriceImage(): string {
      const imageCode = this.myPrize ? this.myPrize.code : '';
      return getPriceImage(imageCode);
    },
    birdAssetMobile(): string {
      return require('@/assets/pictures/advent_birds_mobile.svg');
    },
    birdAssetDesktopLeft(): string {
      return require('@/assets/pictures/advent_birds_desktop_left.svg');
    },
    birdAssetDesktopRight(): string {
      return require('@/assets/pictures/advent_birds_desktop_right.svg');
    },
    hasParticipated(): boolean {
      return (
        this.participated !== Participated.WON &&
        this.participated !== Participated.LOST &&
        document.cookie === 'participated=true'
      );
    },
    title(): string {
      switch (this.participated) {
        case Participated.WON: {
          return this.texts.titles.won;
        }
        case Participated.LOST: {
          return this.texts.titles.lost;
        }
        case Participated.PARTICIPATED_TODAY: {
          return this.texts.titles.participated;
        }
        default: {
          return '';
        }
      }
    },
    titleText(): string {
      console.log(this.participated);
      switch (this.participated) {
        case Participated.WON: {
          return this.texts.titleTexts.won;
        }
        case Participated.LOST: {
          return this.texts.titleTexts.lost;
        }
        case Participated.PARTICIPATED_TODAY: {
          return this.texts.titleTexts.participated;
        }
        default: {
          return '';
        }
      }
    },

    hasJoinedMainRaffle(): boolean {
      return createStore.state.hasJoinedMainRaffle;
    },
    userHasWon(): boolean {
      return this.participated === Participated.WON;
    },
    // styles - have to be inline to work with the way themes are set up
    chevronImage(): object {
      return require(`@/assets/pictures/chevron_easter23.svg`);
    },
    chevronStyle(): object {
      return {
        backgroundImage: `url('${this.chevronImage}')`
      };
    }
  }
});
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
@import '~@/styles/variables.scss';
@import '~@/styles/theme.module.scss';

.result-view {
  padding: rem(120px) 0 0; // prevent issues/clipping with logo
  background-color: $lavender;
  @include tablet-portrait() {
    padding-top: rem(30px);
  }
  @include tablet-landscape() {
    padding-top: rem(30px);
  }
  @include phone-portrait() {
    padding-top: rem(40px);
  }
  @include phone-landscape() {
    padding-top: rem(20px);
  }
  @include desktop() {
    padding-top: rem(120px);
  }

  .icon-container {
    position: fixed;
    width: 100%;
    bottom: rem(40px);
    display: flex;
    height: 40px;
    justify-content: center;
    z-index: 1000;
    .chevron-down {
      animation: 2s linear infinite pulse;
      cursor: pointer;
      border-radius: rem(22px);
      width: rem(40px);
      height: rem(40px);
      display: flex;
      justify-content: center;
      align-items: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .prize-details {
    position: relative;
    padding-bottom: rem(50px);
    background-color: transparent;
    pointer-events: none;

    .background {
      padding-inline: rem(20px);

      .raffle-description {
        margin-top: rem(48px);
        font-size: small;
      }
    }
  }
  .other-details {
    position: relative;
    //z-index: 7;
    padding-top: $padding-s;
    padding-left: $padding-m;
    padding-right: $padding-m;
    background-color: $lower-background-color;

    .other-details-content {
      @include desktop() {
        width: 30%;
        margin-inline: auto;
      }
      @include tablet-landscape() {
        width: 40%;
        margin-inline: auto;
      }
    }
  }
  .info-text::v-deep p {
    // not sure if info-text still required after refactoring, test this
    color: $white;
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
}
</style>
