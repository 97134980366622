
import { defineComponent } from 'vue';
import { Prize } from '@/models/Prize';

export default defineComponent({
  name: 'Title',
  props: {
    title: String,
    titleText: String,
    primaryHeaderParagraph: String,
    fade: {
      type: Boolean,
      default: false
    },
    prizeImage: {
      // expects input of form require('@/assets/pictures/somePic.svg'), so import
      // already resolved by webpack
      type: String,
      default: ''
    },
    showImage: {
      type: Boolean,
      default: false
    },
    myPrize: { type: Object as () => Prize }
  },
  data() {
    return {};
  },
  computed: {
    // TODO: these styles can be done just with dynamic css classes
    titleStyle(): object {
      if (this.fade) {
        return {
          color: 'transparent',
          transition: 'all 0.8s ease-out'
        };
      }
      return {
        color: '#0A123D'
      };
    },
    textStyle(): object {
      if (this.fade) {
        return {
          color: 'transparent',
          transition: 'all 0.8s ease-out'
        };
      }
      return {
        color: '#0A123D'
      };
    },
    hasImage(): boolean {
      return !this.prizeImage || this.prizeImage !== '';
    }
  }
});
