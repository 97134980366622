
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CheckBox',
  props: {
    label: String
  },
  data() {
    return {
      isValid: Boolean,
      inputElement: { type: Object as () => HTMLInputElement },
      value: ''
    };
  },
  computed: {},
  methods: {
    check() {
      const checkbox = document.getElementById('checkbox') as HTMLInputElement;
      this.$emit('checked', checkbox.checked);
    }
  }
});
