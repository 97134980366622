<template>
  <div class="landing-view" :class="{ expired: themeExpired }">
    <div class="title-container" id="title-container">
      <Title class="title" :fade="fadeTitle" :class="{ faded: fadeTitle }" :title="title"></Title>
    </div>

    <div class="lottie-container" id="lottie-container">
      <div class="lottie-container-inner" @click="handleClick">
        <!--Desktop-->
        <LottieAnimation
          v-show="won"
          class="lottie-animation"
          path="animations/advent23/customer_wins/data.json"
          :loop="false"
          :autoPlay="false"
          :width="-1"
          :height="-1"
          renderer="svg"
          :speed="1"
        />
        <LottieAnimation
          v-show="!won"
          class="lottie-animation"
          path="animations/advent23/loeb_wins/data.json"
          :loop="false"
          :autoPlay="false"
          :width="-1"
          :height="-1"
          renderer="svg"
          :speed="1"
        />
      </div>
      <div class="prize-title-container">
        <p class="prize-title">{{ titleIntro }}</p>
        <p class="prize-title-text">{{ titleText }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import axios from 'axios';
import { format } from 'date-fns';
import createStore from '@/store/index';
import Title from '@/components/Title.vue';
import { Participated } from '@/models/general';
import { Prize } from '@/models/Prize';
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue';
import lottie from 'lottie-web';
import { adventTexts2023 } from '@/texts/theme-specific/advent-texts-2023';

export default defineComponent({
  name: 'LandingView',
  components: {
    Title,
    LottieAnimation
  },
  data() {
    return {
      day: format(new Date(), 'd'),
      title: adventTexts2023.titles.landingView,
      won: false,
      wonRequestHasBeenSent: false,
      titleIntro: adventTexts2023.titleTexts.titleIntro
    };
  },
  methods: {
    async goToResults() {
      this.$router.push({ name: 'ResultView' });
    },
    async getPrize() {
      try {
        const prize = await axios.get(`${process.env.VUE_APP_BASE_ENDPOINT}/prize/won`, {
          withCredentials: true
        });
        await createStore.dispatch('setPrize', prize.data);
        await createStore.dispatch('setParticipated', this.myPrize?.id ? Participated.WON : Participated.LOST);
      } catch (error) {
        if (error.response.status === 403) {
          await createStore.dispatch('setParticipated', Participated.PARTICIPATED_TODAY);
        }
        throw error;
      }
    },
    async handleClick() {
      if (!this.wonRequestHasBeenSent) {
        this.wonRequestHasBeenSent = true;
        // have to prevent further clicks/calls after the first one is initialized, else navigation
        // MAY break (double navigation, inconsistent participated state)
        await createStore.commit('setFadeOnAnimationHasStarted', true);
        await this.getPrize();
        this.won = createStore.state.participated === Participated.WON;

        setTimeout(() => {
          this.playAnimation();
        }, 100); // -.1s off the css transition time, to ensure no gap between scale and animation
      }
    },
    playAnimation() {
      lottie.play();
      setTimeout(() => {
        this.initiateTransition();
        setTimeout(() => {
          this.goToResults();
        }, 1000);
      }, 5500);
    },
    setLottieHeight() {
      const container = document.querySelector('#lottie-container') as HTMLElement;
      if (!container) {
        return;
      }
      const coordinates = container.getBoundingClientRect();
      const footerMargin = 100;
      const windowHeight = document.documentElement.clientHeight;
      const availableHeight = windowHeight - coordinates.top - footerMargin;
      container.style.height = `${availableHeight}px`;
    },
    async initiateTransition() {
      await createStore.dispatch('setShowOverlay', true);
    }
  },
  async created() {
    this.setLottieHeight();
    if (document.cookie.split(';').some(item => item.includes('participated=true'))) {
      await createStore.dispatch('setParticipated', Participated.PARTICIPATED_TODAY);
      this.$router.push({ name: 'ResultView' });
    }
    window.addEventListener('resize', () => {
      setTimeout(() => {
        this.setLottieHeight();
      }, 200);
    });
  },
  mounted() {
    this.setLottieHeight();
  },
  computed: {
    dayLabelX(): number {
      // TODO: A perfect solution would require a lot of individual values for specific days...
      // Doing the destinction between one and two digits is the bare minimum.
      return +this.day < 10 ? 55.864 : 50;
    },
    myPrize(): Prize | undefined | null {
      return createStore.state.prize;
    },
    fadeTitle(): boolean {
      return createStore.state.fadeOnAnimationHasStarted;
    },
    themeExpired(): boolean {
      return createStore.state.themeExpired;
    },
    hasWon(): boolean {
      return createStore.state.participated === Participated.WON;
    },
    hasLost(): boolean {
      return createStore.state.participated === Participated.LOST;
    },
    prizeDescription(): undefined | string {
      return createStore.state.prizeDescription;
    },
    titleText(): string {
      if (this.prizeDescription) {
        return `${this.prizeDescription}`;
      }
      return '';
    },
    primaryHeaderText(): string | undefined {
      if (this.prizeDescription) {
        return 'Tagesgewinn:';
      }
      return undefined;
    },
    videoDesktopWinSrc(): string {
      return require('@/assets/movies/Desktop_win.webm');
    },
    videoDesktopLossSrc(): string {
      return require('@/assets/movies/Desktop_loss.webm');
    }
  }
});
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
@import '~@/styles/variables.scss';
@import '~@/styles/theme.module.scss';

.landing-view {
  padding: 0; // prevent issues/clipping with logo
  background-color: transparent;

  @include desktop() {
    padding-top: rem(70px);
  }
  @include tablet-portrait() {
    padding-top: rem(70px);
  }
  @include tablet-landscape() {
    padding-top: rem(70px);
  }
  @include phone-portrait() {
    padding-top: rem(30px);
  }
  @include phone-landscape() {
    padding-top: rem(30px);
  }
  &.expired {
    background-color: $white; // prevent flicker through when closing impressum
  }

  .title-container {
    position: relative;
    z-index: 12;
    width: 100%;
    height: fit-content;

    .title {
      z-index: 12; // in front of animation in case of clipping
      position: relative;

      padding-bottom: $padding-m;
      margin-left: $padding-l;
      margin-right: $padding-l;
      transition: all 0.8s ease-out;
      @include phone-portrait() {
        padding-bottom: 0;
      }

      &.faded {
        color: transparent;
      }
    }
  }

  .lottie-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 10;
    width: 100%;
    min-height: 300px;
    overflow: visible;
    @include desktop() {
      margin-top: rem(48px);
    }
    @include tablet-portrait() {
      margin-top: rem(48px);
    }
    @include tablet-landscape() {
      margin-top: rem(48px);
    }
    @include phone-portrait() {
      margin-top: rem(50px);
    }
    @include phone-landscape() {
      margin-top: rem(0px);
    }
    margin-left: rem(0px);

    &.animated {
      // animation advent-22 starts zoom in at 4s, hence scale up until then
      //-webkit-transition: all 4s ease-in;
      //transition: all 4s ease-in;
    }

    .lottie-container-inner {
      display: flex;
      justify-content: center;
      height: 80%;
      position: relative;
      width: 100%;
      overflow: visible;
      margin-top: rem(48px);

      .lottie-animation {
        position: relative;
        z-index: 11;
        overflow: visible;
        transform: translate3d(0px, -20%, 0px) scale(0.8);
        min-height: 250px;
        @include phone-portrait() {
          transform: translate3d(0px, -20%, 0px) scale(0.8);
        }
        @include tablet-portrait() {
          transform: translate3d(0px, -20%, 0px) scale(1);
        }
        @include tablet-landscape() {
          transform: translate3d(0px, -30%, 0px) scale(1);
        }
        @include small-phone-portrait() {
          transform: translate3d(0px, -40%, 0px) scale(1.1);
        }
        @include small-phone-landscape() {
          transform: translate3d(0px, -40%, 0px) scale(1.1);
        }
        &.desktop {
          @include tablet-portrait() {
            display: none;
          }
          @include phone-portrait() {
            display: none;
          }
          @include phone-landscape() {
            display: none;
          }
        }
        &.mobile {
          @include desktop() {
            display: none;
          }
          @include tablet-landscape() {
            display: none;
          }
        }
      }
    }
  }

  .prize-title-container {
    align-self: center;
    display: flex;
    flex-direction: column;
  }

  .prize-title {
    text-align: center;
    z-index: 999999;
  }

  .prize-title-text {
    font-weight: bold;
    text-align: center;
    z-index: 999999;
  }

  .distance-error {
    margin: $padding-x-s 0 $padding-x-s 0;
    text-align: center;
  }
}
</style>
