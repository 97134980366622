export enum Participated {
  WON = 'won',
  LOST = 'lost',
  PARTICIPATED_TODAY = 'participated',
  NOT_PARTICIPATED = 'notParticipated'
}

export enum ErrorTypes {
  EXPIRED = 'expired',
  THEME_EXPIRED = 'themeExpired',
  LOCATION_DISABLED = 'locationDisabled',
  TOO_FAR = 'tooFar'
}

export enum AssetSituations {
  NORMAL = 'normal',
  ERROR = 'error',
  WIN = 'win'
}
