
import { defineComponent } from 'vue';
import createStore from '@/store/index';
import Title from '@/components/Title.vue';
import { ErrorTypes } from '@/models/general';
import BigButton from '@/components/BigButton.vue';
import { getCurrentTexts } from '@/texts/textService';

export default defineComponent({
  name: 'ErrorView',
  components: {
    Title,
    BigButton
  },
  data() {
    return {
      // not sure why this one text is hard-coded, but I'm gonna leave it here for now.
      subtitle2: 'Hauptverlosung',
      texts: getCurrentTexts(),
      mainRaffleInfo: getCurrentTexts().infoTexts.mainRaffle,
      mainRaffleDateInfo: getCurrentTexts().infoTexts.mainRaffleDate,
      titleWithImageTitle: getCurrentTexts().titleWithImageTexts.participated,
      hasJoinedMainRaffleInfo: getCurrentTexts().infoTexts.hasJoinedMainRaffle,
      farFromWindowMessage: getCurrentTexts().errorMessages.farFromWindow,
      locationInfo: getCurrentTexts().errorMessages.locationInfo,
      locationInstructions: getCurrentTexts().errorMessages.locationInstructions,
      buttonText: getCurrentTexts().buttons.stayInformed
    };
  },
  methods: {
    showInstructions(): boolean {
      // NOTE: is a non-issue since the location thing is no longer being utilized
      return this.errorType === ErrorTypes.LOCATION_DISABLED || this.errorType === ErrorTypes.TOO_FAR;
    },
    openLoebMainPage() {
      window.open('https://loeb.ch/', '_blank');
    }
  },
  computed: {
    hasJoinedMainRaffle(): boolean {
      return createStore.state.hasJoinedMainRaffle;
    },
    errorType(): string {
      const errorType = this.$route.query.error;
      return errorType as string;
    },
    errorImage(): object {
      return require('@/assets/pictures/circleAdventPhrase.png');
    },
    themeExpired(): boolean {
      return this.errorType === ErrorTypes.THEME_EXPIRED;
    },
    title(): string {
      switch (this.errorType) {
        case ErrorTypes.EXPIRED: {
          return this.texts.titles.expired;
        }
        case ErrorTypes.THEME_EXPIRED: {
          return this.texts.titles.themeExpired;
        }
        default: {
          return this.texts.titles.defaultError;
        }
      }
    },
    errorMessage(): string {
      switch (this.errorType) {
        case ErrorTypes.EXPIRED: {
          return this.texts.errorMessages.expired;
        }
        case ErrorTypes.THEME_EXPIRED: {
          return this.texts.errorMessages.themeExpired;
        }
        case ErrorTypes.LOCATION_DISABLED: {
          return this.texts.errorMessages.locationDisabled;
        }
        case ErrorTypes.TOO_FAR: {
          return this.texts.errorMessages.farFromWindow;
        }
        default: {
          return '';
        }
      }
    }
  }
});
