
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BigButton',
  props: {
    text: String,
    disabled: Boolean,
    isThemed: {
      /*
      if isThemed, the dynamic inline styles are applied which use the colors
      from the currentTheme. otherwise, the colorClass (based on this.color) is used
      */
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'black'
    }
  },
  data() {
    return {};
  },
  methods: {
    emitClicked() {
      this.$emit('button-clicked');
    }
  },
  computed: {
    colorClass(): object {
      return {
        [`${this.color}`]: true
      };
    }
  }
});
