import { TextScheme } from '@/models/Texts';
import {
  getDefaultTitles,
  getDefaultSubTitles,
  getDefaultTitleTexts,
  getDefaultTitleWithImageTexts,
  getDefaultImprint,
  getDefaultInfoTexts,
  getDefaultFormLabels,
  getDefaultButtonLabels,
  getDefaultErrorMessages,
  getDefaultCouponTexts
} from '@/texts/textService';

/*
Since default texts are based on those of this particular event, it will simply
return the defaults everywhere
 */

export const easterTexts2022: TextScheme = {
  titles: getDefaultTitles(),
  subTitles: getDefaultSubTitles(),
  titleTexts: {
    ...getDefaultTitleTexts(),
    hasJoinedMainRaffle:
      'Vielen Dank für Ihre Teilnahme an der Hauptverlosung. Die Ziehung der Gewinnerin oder des Gewinners findet zwischen dem 19.04. und 22.04.2022 statt.'
  },
  titleWithImageTexts: {
    ...getDefaultTitleWithImageTexts(),
    mainPrize: `Einkaufsgutschein von LOEB im Wert von CHF 1'000.-`
  },
  imprint: {
    ...getDefaultImprint(),
    copyright: `<p><span class="bold-span">© 2022 Loeb AG</span>
</p>`
  },
  infoTexts: {
    ...getDefaultInfoTexts(),
    mainRaffleDate: `<p>
  Die Ziehung des Gewinners findet zwischen dem 19.04. und 22.04.2022 statt.
</p>`,
    won: `<p>Bitte hinterlegen Sie Ihre Angaben, damit wir Sie kontaktieren können. Den Gewinn senden wir Ihnen gerne per Post zu.
Sie nehmen automatisch an der Verlosung des Hauptgewinns teil.</p>`
  },
  formLabels: getDefaultFormLabels(),
  buttons: getDefaultButtonLabels(),
  errorMessages: getDefaultErrorMessages(),
  couponTexts: getDefaultCouponTexts()
};
