import Base from '@/views/Base.vue';
import ErrorView from '@/views/ErrorView.vue';
import ImprintView from '@/views/ImprintView.vue';
import LandingView from '@/views/LandingView.vue';
import ResultView from '@/views/ResultView.vue';
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'Base',
    component: Base,
    children: [
      {
        path: '/',
        name: 'LandingView',
        component: LandingView
      },
      {
        path: '/results',
        name: 'ResultView',
        component: ResultView
      }
    ]
  },
  {
    path: '/imprint',
    name: 'ImprintView',
    component: ImprintView
  },
  {
    path: '/error',
    name: 'ErrorView',
    component: ErrorView
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
