<template>
  <div :class="[{ error: errorMessage }, 'input-field']">
    <input
      :required="required"
      type="text"
      name="id"
      :placeholder="placeholder"
      :id="placeholder"
      v-model="value"
      v-on:blur="emitBlur"
      :maxlength="maxLength"
    />
    <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'InputField',
  props: {
    required: {
      type: Boolean,
      default: false
    },
    placeholder: String,
    type: String,
    errorMessage: String,
    maxLength: {
      type: Number,
      default: 50
    }
  },
  data() {
    return {
      inputElement: { type: Object as () => HTMLInputElement | null },
      value: ''
    };
  },
  computed: {
    isValid(): boolean {
      // errorMessage(s) initialized with empty string in RaffleForm
      return this.errorMessage === '';
    }
    // // styles - have to be inline to work with the way themes are set up
    // inputStyle(): object {
    //   const color = this.isValid
    //     ? this.currentTheme.fontColors.textOnFooterAssetColor
    //     : this.currentTheme.fontColors.errorTextColor;
    //   const borderColor = this.isValid
    //     ? this.currentTheme.fontColors.textOnFooterAssetColor
    //     : this.currentTheme.fontColors.errorTextColor;
    //   return {
    //     // CANNOT inline style placeholder font color, see '/styles/theme.module.scss' for that
    //     backgroundColor: this.currentTheme.assetColors.footerAssetColor,
    //     color: color,
    //     border: `1px solid ${borderColor}`
    //   };
    // },
    // errorTextStyle(): object {
    //   return {
    //     color: this.currentTheme.fontColors.errorTextColor
    //   };
    // }
  },
  methods: {
    emitBlur() {
      this.$emit('blur', this.value);
    },
    validate(value: string): boolean {
      const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailPattern.test(value);
    }
  }
});
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
@import '~@/styles/theme.module.scss';

.input-field {
  padding-top: $padding-xs;
  padding-bottom: $padding-xs;
  &.error {
    input {
      border-width: 2px;
    }
    input[type='text'] {
      background-color: $lower-background-color;
      border: 2px solid $error-color;
      color: $error-color;
    }
    input::placeholder {
      color: $error-color;
    }
  }
  input {
    width: 100%;
    height: rem(48px);
    border-radius: rem(23.5px);
    box-sizing: border-box;
    -webkit-appearance: none;
    background-color: $lower-background-color;
    border: 1px solid $white;
    color: $white;
  }
  input[type='text'] {
    padding: rem(17px) rem(22px) rem(17px) rem(22px);
    font-family: 'proxima-nova';
    font-size: rem(16px);
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: rem(22px);
    letter-spacing: normal;
    @media only screen and (min-device-width: $max-width-mobile) and (max-device-width: $max-width-tablet) {
      // Tablets
      font-size: $font-size-tablet-normal;
    }
    @media only screen and (min-device-width: $max-width-tablet) {
      // Desktop
      font-size: $font-size-desktop-normal;
    }
  }
  input:not(output):-moz-ui-invalid {
    box-shadow: unset;
    // removes absolutely hideous validation border in firefox (https://stackoverflow.com/a/21198397)
  }
  input:focus {
    border-width: rem(2px);
    outline: none;
  }
  input::placeholder {
    color: $white;
    opacity: 1;
  }
  .error-message {
    padding-top: rem(6px);
    padding-left: rem(23.5px);
    font-size: rem(12px);
    color: $error-color;
    @media only screen and (min-device-width: $max-width-mobile) and (max-device-width: $max-width-tablet) {
      // Tablets
      font-size: rem(14px);
    }
    @media only screen and (min-device-width: $max-width-tablet) {
      // Desktop
      font-size: rem(16px);
    }
    margin: 0;
  }
}
</style>
