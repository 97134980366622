
import { defineComponent } from 'vue';
import { adventTexts2023 } from '@/texts/theme-specific/advent-texts-2023';

export default defineComponent({
  name: 'ImprintView',
  components: {},
  data() {
    return {
      prize: undefined,
      title: adventTexts2023.imprint.title,
      editing: adventTexts2023.imprint.editing,
      design: adventTexts2023.imprint.design,
      legal: adventTexts2023.imprint.legal,
      copyright: adventTexts2023.imprint.copyright,
      subtitle: 'Impressum'
    };
  },
  methods: {
    close() {
      if (window.history.length > 2) {
        this.$router.go(-1);
      } else {
        console.log('pushing to base');
        this.$router.push('/');
      }
    },
    resetScrollPosition() {
      document.getElementById('app')?.scrollTo(0, 0);
    }
  },
  created() {
    this.resetScrollPosition();
  }
});
