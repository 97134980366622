import { Theme } from '@/models/Theme';
import { getTextsForTheme } from '@/texts/textService';

// define all used colors
export const colors = {
  YELLOW: '#e8bb36',
  WHITE: '#ffffff',
  LAVENDER: '#AB95C7',
  DARK_RED: '#871226'
};
/*
"re-package" colors into categories that are likely modified together
  (e.g. every dark accent likely has the same color)
if a given color in the actual settings does not fit a category just pull
  it directly from the 'colors' (e.g. would be the dark grey text on the background)
*/
const themeColors = {
  ACCENT_COLOR: colors.YELLOW,
  BACKGROUND_UPPER_COLOR: colors.LAVENDER,
  BACKGROUND_LOWER_COLOR: colors.DARK_RED,
  FONT_COLOR: colors.WHITE
};

const adventTheme2023: Theme = {
  assetSettings: {
    usePlainBackgroundHeaderAsset: true,
    usePlainBackgroundFooterAsset: true,
    // e.g. for Advent 21, there were no actual assets like grass, just a plain green background
    headerAssetHasDedicatedGradient: false,
    footerAssetHasDedicatedGradient: false,
    // if there still should be an gradient, set these to true
    repeatHeaderAsset: false,
    repeatFooterAsset: true,
    // if the respective assets are not to be repeated, set these to false
    // e.g. Advent 21 had the branch-thingy hanging from the top
    useHeaderAssetFlip: true,
    useFooterAssetFlip: false,
    // if set to true, and assets are chained, some are alternative versions to introduce more variety
    logoHasDedicatedBackgroundBar: false,
    // allows using a gradient (specified in AssetStyles)
    hasDedicatedWinHeaderAsset: false,
    hasDedicatedWinFooterAsset: true,
    hasDedicatedErrorHeaderAsset: true,
    hasDedicatedErrorFooterAsset: false,
    // controls for dedicated assets
    disableStandardHeaderAssetOnWin: false,
    disableStandardHeaderAssetOnError: true,
    disableStandardFooterAssetOnWin: false,
    disableStandardFooterAssetOnError: false,
    // hide standard asset under certain conditions
    lottieScale: 1
    // scale of the animation container
  },
  assetStyles: {
    logoBackgroundStyle: `linear-gradient(to bottom, ${themeColors.BACKGROUND_UPPER_COLOR}, ${themeColors.BACKGROUND_UPPER_COLOR})`,
    // can fake uniform color by having twice the same color in the gradient
    footerAssetStyle: `linear-gradient(to bottom, ${themeColors.BACKGROUND_LOWER_COLOR}, ${themeColors.BACKGROUND_LOWER_COLOR})`,
    headerAssetStyle: ''
  },
  assetPaths: {
    // Images
    headerAssetPath: 'pictures/advent_birds_mobile.svg',
    headerAssetPathFlipped: '',
    footerAssetPath: '',
    footerAssetPathFlipped: '',
    headerAssetPathWin: '',
    headerAssetPathError: 'pictures/misteln_loeb_stylized_combined.png',
    footerAssetPathWin: 'pictures/gift_open.png',
    footerAssetPathError: '',
    logoAssetPath: 'pictures/logo_yellow.svg',
    chevronButtonAssetPath: 'pictures/chevron_advent21.png',
    mainPrizeImagePath: '',
    // Animations
    LottieAssetPathLoebWins: 'animations/advent23/loeb_wins/data.json', // TODO: 2023
    LottieAssetPathCustomerWins: 'animations/advent23/customer_wins/data.json' //TODO: 2023
  },
  logoSpecification: {
    useCustomHeight: true,
    customHeight: '2rem',
    useCustomOffset: true,
    customOffset: '0'
  },
  lottieSpecification: {
    useCustomOffsetLeft: false,
    customOffsetLeft: ''
  },
  footerAssetSpecification: {
    assetWidth: 857,
    assetHeight: 680,
    // dimensions of the asset
    targetPercentageHeight: 0.15,
    // specifies how much of the entire screen height the asset is to grow to (approximately)
    assetOverlapPercentage: 0.5,
    // specifies how much overlap there should be between chained assets
    flips: [],
    // sequence of booleans that impact which assets in the chain are replaced by their
    // alternative / "flipped" version
    disableFlips: true,
    // WIN specific settings
    winTargetPercentage: 0.3,
    // specify roughly tha percentage of screen-height the asset should scale to
    winAssetHeight: 852,
    winAssetWidth: 1132,
    // if no asset, set dimensions to 1 to prevent zero-division shenanigans
    useCustomWinAssetOffsetBottom: true,
    customWinAssetOffsetBottom: '3rem',
    // ERROR specific settings
    errorTargetPercentage: 0.3,
    // specify roughly the percentage of screen-height the asset should scale to
    errorAssetHeight: 852,
    errorAssetWidth: 1132,
    // if no asset, set dimensions to 1 to prevent zero-division shenanigans
    useCustomErrorAssetOffsetBottom: true,
    customErrorAssetOffsetBottom: '3rem'
    // define custom bottom offset
  },
  headerAssetSpecification: {
    assetWidth: 375,
    assetHeight: 140,
    // dimensions of the asset
    targetPercentageHeightDesktop: 0.5,
    targetPercentageHeightTablet: 0.5,
    targetPercentageHeightTabletPortrait: 0.45,
    targetPercentageHeightMobile: 0.5,
    targetPercentageHeightMobilePortrait: 0.5,
    // specifies how much of the entire screen height the asset is to grow to (approximately)
    // on the given medium and the given orientation
    assetOverlapPercentage: 0.15,
    // specifies how much overlap there should be between chained assets
    flips: [],
    // sequence of booleans that impact which assets in the chain are replaced by their
    // alternative / "flipped" version
    disableFlips: true,
    // set to true to enforce no use of flipped assets
    setCustomOffsetLeft: true,
    customOffsetLeft: -1.5,
    setCustomOffsetTop: true,
    // the offset top is intended to shift an asset to e.g. start below the logo bar
    // so don't forget to account for both the height AND the padding of the bar
    // 2 + 2x .9375 = 3.875 - 1 rem margin = 2.875
    customOffsetTop: '2.875rem',
    // allows us to position an element "manually". useful for e.g. non-repeating assets
    // WIN/ERROR specific settings
    winTargetPercentage: 0.7,
    // specify roughly tha percentage of screen-height the asset should scale to
    winAssetHeight: 4328,
    winAssetWidth: 1856,
    // if no asset, set dimensions to 1 to prevent zero-division shenanigans
    correctWinForStandardAssetOffsetTop: true
    // standardAssetOffset has to be applied to the overall container. set this
    // to true to reverse the offset for the dedicated win offset
  },
  resultsViewSpecification: {
    useCustomOffsetTop: true,
    customOffsetTop: '-4.5rem',
    useDifferentCustomLoseOffsetTop: false,
    customLoseOffsetTop: '-4.5rem',
    mainPrizeHasImage: false
  },
  assetColors: {
    headerAssetColor: themeColors.BACKGROUND_UPPER_COLOR,
    footerAssetColor: themeColors.BACKGROUND_LOWER_COLOR,
    backgroundColor: themeColors.BACKGROUND_UPPER_COLOR
  },
  fontColors: {
    // texts on central part of screen
    titleOnBackgroundColor: themeColors.ACCENT_COLOR,
    textOnBackgroundColor: themeColors.FONT_COLOR,
    // texts on lower part of screen
    titleOnFooterAssetColor: themeColors.FONT_COLOR,
    textOnFooterAssetColor: themeColors.FONT_COLOR,
    formTextColor: themeColors.FONT_COLOR,
    // error specific colors
    errorTextColor: themeColors.FONT_COLOR // TODO: likely some red
  },
  chevronColors: {
    mainColor: themeColors.ACCENT_COLOR,
    borderColor: themeColors.ACCENT_COLOR
  },
  buttonColors: {
    mainColor: themeColors.BACKGROUND_UPPER_COLOR,
    fontColor: themeColors.FONT_COLOR
  },
  texts: getTextsForTheme('advent2023') // TODO: correct texts
};

export { adventTheme2023 };
