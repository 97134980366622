<template>
  <div class="app" :class="{ expired: themeExpired }" :style="appBackgroundStyle" v-if="!isLoading">
    <div class="overlay" :class="{ visible: showOverlay }" />
    <div class="loeb-logo">
      <div class="logo" id="logo" :style="{ backgroundImage: `url('${logoImage}')` }"></div>
    </div>
    <router-view />
    <div class="footer-color-cover" v-if="showFooter" :style="footerCoverStyle">
      <div class="footer" :class="{ expired: showThemeExpired }">
        <div @click="goToImpressum()" :style="impressumTextStyle">Impressum</div>
      </div>
    </div>
    <div
      class="impressum-landing"
      v-if="$route.name === 'LandingView'"
      @click="goToImpressum()"
      :style="impressumTextStyle"
    >
      Impressum
    </div>
    <div
      class="impressum-result"
      v-if="$route.name === 'ResultView'"
      @click="goToImpressum()"
      :style="impressumTextStyle"
    >
      Impressum
    </div>
    <div
      class="impressum-error"
      v-if="$route.name === 'ErrorView'"
      @click="goToImpressum()"
      :style="impressumTextStyle"
    >
      Impressum
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Base from '@/views/Base.vue';
import axios from 'axios';
import { ErrorTypes } from './models/general';
import createStore from '@/store/index';
import { colors } from './configuration/themes/advent-theme-2023';

@Options({
  components: {
    Base
  }
})
export default class App extends Vue {
  isLoading = true;

  goToImpressum() {
    this.$router.push({ name: 'ImprintView' });
  }
  get showFooter(): boolean {
    return this.$route.name === 'Base';
  }

  get showOverlay(): boolean {
    return createStore.state.showOverlay;
  }

  get themeExpired() {
    return createStore.state.themeExpired;
  }

  get eventHasStarted() {
    return createStore.state.eventStarted;
  }

  get showThemeExpired() {
    return this.themeExpired || !this.eventHasStarted || this.isThemeExpiredPageVisit;
  }

  get isImpressum(): boolean {
    return this.$route.name === 'ImprintView';
  }

  get isOnLandingView(): boolean {
    return this.$route.name === 'LandingView';
  }

  get fadeLogoActivated(): boolean {
    return createStore.state.fadeOnAnimationHasStarted;
  }

  get fadeLogo(): boolean {
    return this.fadeLogoActivated && this.isOnLandingView;
  }

  get isThemeExpiredPageVisit(): boolean {
    // used to also catch manual visits and make some style changes that are otherwise
    // only applied if the theme is acutally expired
    return this.$route.fullPath === '/error?error=themeExpired';
  }

  get hideLoebLogoBackground(): boolean {
    return this.isImpressum || this.themeExpired || this.isThemeExpiredPageVisit;
  }

  get logoImage(): object {
    return require(`@/assets/pictures/logo.svg`);
  }

  get appBackgroundStyle(): object {
    let color = colors.WHITE;
    if (!this.themeExpired) {
      color = colors.LAVENDER;
    }
    return {
      backgroundColor: color,
      height: '100vh'
    };
  }
  get footerCoverStyle(): object {
    let color = colors.WHITE;
    if (!this.themeExpired) {
      color = colors.LAVENDER;
    }
    return {
      backgroundColor: color
    };
  }
  get impressumTextStyle(): object {
    if (this.showThemeExpired) {
      // since on white background, always set style to black text
      return {
        color: 'black'
      };
    }
    if (this.$route.name === 'ResultView') {
      // since on purple background, always set style to white text
      return {
        color: 'white'
      };
    }
    return {
      color: 'black'
    };
  }

  async created() {
    this.isLoading = true;
    const expirationData = await axios.get(`${process.env.VUE_APP_BASE_ENDPOINT}/expiration`);
    await createStore.dispatch('setEventStarted', expirationData.data.hasEventStarted);
    const isExpired = expirationData.data.isExpired;
    let themeIsExpired = expirationData.data.themeIsExpired;
    // themeIsExpired -> will show a much more generic, non-themed Endscreen
    const hasEventStarted = expirationData.data.hasEventStarted;
    themeIsExpired = themeIsExpired || !hasEventStarted;
    // if event has not started yet, still show generic themIsExpired screen
    // may have to be refined in the future for a separate screen/overlay
    if (!themeIsExpired) {
      const appWrapper = document.getElementById('app');
      if (appWrapper) {
        // NOTE: only sets actual color if not expired. prevents color flicker through expire overlay
        appWrapper.style.backgroundColor = colors.WHITE;
      }
    }
    if (themeIsExpired) {
      await createStore.dispatch('setThemeExpired', true);
      this.$router.push({ name: 'ErrorView', query: { error: ErrorTypes.THEME_EXPIRED } });
      // prevent scroll-y, modify colors to prevent purple flicker
      const appWrapper = document.getElementById('app');
      if (appWrapper) {
        appWrapper.style.position = 'fixed';
        appWrapper.style.top = '0';
        appWrapper.style.bottom = '0';
        appWrapper.style.left = '0';
        appWrapper.style.right = '0';
      }
    } else if (isExpired) {
      this.$router.push({ name: 'ErrorView', query: { error: ErrorTypes.EXPIRED } });
    } else {
      // since not expired or themeExpired, load prize description
      const prizeDescription = await axios.get(`${process.env.VUE_APP_BASE_ENDPOINT}/prize/prize-description-today`);
      await createStore.dispatch('setPrizeDescription', prizeDescription.data);
    }
    this.isLoading = false;
  }

  updateAppWrapperBackground() {
    const appWrapper = document.getElementById('app');
    // set appWrapper background to white if is impressum
    // -> prevents visual issues for devices that need to scroll in impressum
    if (appWrapper) {
      if (this.isImpressum || this.isThemeExpiredPageVisit) {
        appWrapper.style.backgroundColor = colors.WHITE;
      } else {
        appWrapper.style.backgroundColor = colors.LAVENDER;
      }
    }
  }

  updated() {
    // TODO: on routeChange or something, there has to be a better way
    // couldn't get the watcher to work somehow, hence: https://stackoverflow.com/a/56325120
    this.updateAppWrapperBackground();
  }
}
</script>

<style lang="scss">
@import '~@/styles/global.scss';
@import '~@/styles/theme.module.scss';
.app-wrapper {
  background-color: $lavender;
  position: relative;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  //https://www.w3schools.com/howto/howto_css_hide_scrollbars.asp
}
.app-wrapper::-webkit-scrollbar {
  display: none;
}
.top-drip {
  height: 136px;
}
.overlay {
  background-color: transparent;
  position: fixed;
  z-index: 10000;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: None;
  transition: all 1s ease-out;
  &.visible {
    pointer-events: all;
    background-color: $white;
  }
}
.app {
  position: relative;
  &.expired {
    overflow: hidden;
    background-color: $white;
    //min-height: 100vh;
    min-height: 60vh;
  }
}
.loeb-logo {
  pointer-events: none;
  transition: all 0.8s ease-out;

  &.faded {
    opacity: 0;
  }
  .logo {
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center center;
    width: rem(120px);
    height: rem(35px);
    @include desktop() {
      height: rem(52px);
    }
    @include tablet-portrait() {
      height: rem(45px);
    }
    @include tablet-landscape() {
      height: rem(45px);
    }
    @include phone-portrait() {
      height: rem(45px);
    }
    @include phone-landscape() {
      height: rem(45px);
    }
  }
  z-index: 100;
  position: absolute;
  top: rem(41px);
  width: 100%;
  height: rem(35px);
  padding-top: rem(15px);
  padding-bottom: rem(15px);
  display: flex;
  justify-content: center;
  align-items: center;
  @include phone-landscape() {
    top: rem(21px);
  }
}

.impressum-landing {
  position: static;
  bottom: rem(0px);
  display: flex;
  justify-content: center;
  width: 100%;
  @include desktop() {
    margin-top: rem(30px);
  }
  @include phone-landscape() {
    margin-top: rem(200px);
    padding-bottom: rem(18px);
  }
  @include phone-portrait() {
    margin-top: rem(0px);
    padding-bottom: rem(0px);
  }
  @include tablet-portrait() {
    margin-top: rem(0px);
  }
  @include tablet-landscape() {
    margin-top: rem(30px);
    height: rem(45px);
  }
  @include small-phone-landscape() {
    margin-top: rem(200px);
    padding-bottom: rem(18px);
  }
  @include small-phone-portrait() {
    margin-top: rem(50px);
    padding-bottom: rem(18px);
  }
}

.impressum-result {
  display: flex;
  justify-content: center;
  padding-top: rem(18px);
  padding-bottom: rem(18px);
  width: 100%;
  background-color: $purple;
}

.impressum-error {
  display: flex;
  justify-content: center;
  width: 100%;
  @include desktop() {
    margin-top: rem(0px);
  }
  @include phone-landscape() {
    margin-top: rem(20px);
    padding-bottom: rem(18px);
  }
  @include phone-portrait() {
    margin-top: rem(0px);
    padding-bottom: rem(0px);
  }
  @include tablet-portrait() {
    margin-top: rem(50px);
  }
  @include tablet-landscape() {
    height: rem(45px);
  }

  @include small-phone-landscape() {
    margin-top: rem(0px);
    padding-bottom: rem(18px);
  }
  @include small-phone-portrait() {
    margin-top: rem(0px);
    padding-bottom: rem(18px);
  }
}

.footer-color-cover {
  height: rem(250px);
  background-color: $white;
  position: absolute;
  bottom: rem(-85px);
  width: 100%;
}

.footer {
  font-family: 'proxima-nova';
  font-size: rem(16px);
  font-weight: 300;
  color: $white;
  margin-right: rem(24px);
  margin-bottom: rem(14px);
  position: absolute;
  z-index: 200;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  cursor: pointer;
  &.expired {
    position: fixed;
    color: $black;
  }
  background-color: $white;
}
</style>
