import { TextScheme } from '@/models/Texts';
import {
  getDefaultButtonLabels,
  getDefaultCouponTexts,
  getDefaultErrorMessages,
  getDefaultFormLabels,
  getDefaultImprint,
  getDefaultInfoTexts,
  getDefaultSubTitles,
  getDefaultTitles,
  getDefaultTitleTexts,
  getDefaultTitleWithImageTexts
} from '@/texts/textService';

export const easterTexts2023: TextScheme = {
  titles: getDefaultTitles(),
  subTitles: getDefaultSubTitles(),
  titleTexts: {
    ...getDefaultTitleTexts(),
    hasJoinedMainRaffle:
      'Vielen Dank für Ihre Teilnahme an der Hauptverlosung. Die Ziehung der Gewinnerin oder des Gewinners findet zwischen dem 11.04. und 14.04.2023 statt.'
  },
  titleWithImageTexts: {
    ...getDefaultTitleWithImageTexts(),
    mainPrize: `LOEB Gutschein im Wert von CHF 500.-`
  },
  imprint: {
    ...getDefaultImprint(),
    copyright: `<p><span class="bold-span">© 2022 Loeb AG</span>
    </p>`
  },
  infoTexts: {
    ...getDefaultInfoTexts(),
    mainRaffleDate: `<p>
    Die Ziehung des Gewinners findet zwischen dem 11.04.2023 und 14.04.2023 statt.
    </p>`,
    won: `<p>Bitte hinterlegen Sie Ihre Angaben, damit wir Sie kontaktieren können. Den Gewinn senden wir Ihnen gerne per Post zu.
Sie nehmen automatisch an der Verlosung vom Hauptgewinn teil.</p>`
  },
  formLabels: getDefaultFormLabels(),
  buttons: getDefaultButtonLabels(),
  errorMessages: getDefaultErrorMessages(),
  couponTexts: getDefaultCouponTexts()
};
