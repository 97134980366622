<template>
  <div class="subtitle-text">
    <div class="subtitle">
      <h3 class="text">
        {{ subtitle }}
      </h3>
    </div>

    <slot v-if="isImpressum"></slot>
    <div v-else class="alternative">
      <!--    allows for dynamic inline styling - unlike when using slot-->
      <div v-for="(content, index) in varContents" :key="index">
        <div class="info-text" :class="{ unset: infoTextMarginBottomUnset }" v-html="content"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SubtitleText',
  props: {
    subtitle: String,
    isImpressum: {
      type: Boolean,
      default: false
    },
    varContents: {
      type: Array,
      default: () => []
    },
    infoTextMarginBottomUnset: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  }
});
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
@import '~@/styles/theme.module.scss';

.subtitle-text {
  margin-bottom: $padding-l;
  .subtitle {
    display: flex;
    .text {
      color: $white;
    }
  }
  .alternative {
    .info-text::v-deep p {
      color: $white;
      &.unset {
        margin-bottom: unset;
      }
    }
  }
  text-align: start;
}
</style>
