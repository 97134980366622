<template>
  <div class="raffle-form">
    <fieldset class="radio-buttons">
      <div class="radio-button-container">
        <input v-model="gender" type="radio" name="gender-radio-button" id="herr" value="herr" checked />
        <label for="herr">Herr</label>
      </div>
      <div class="radio-button-container">
        <input v-model="gender" type="radio" name="gender-radio-button" id="frau" value="frau" />
        <label for="frau">Frau</label>
      </div>
      <div class="radio-button-container">
        <input v-model="gender" type="radio" name="gender-radio-button" id="divers" value="divers" />
        <label for="divers">Divers</label>
      </div>
    </fieldset>
    <InputField
      @blur="firstNameInput"
      :errorMessage="firstNameError"
      :required="required"
      name="firstName"
      :placeholder="firstNamePlaceholder"
    ></InputField>
    <InputField
      @blur="lastNameInput"
      :errorMessage="lastNameError"
      :required="required"
      name="lastName"
      :placeholder="lastNamePlaceholder"
    ></InputField>
    <InputField
      @blur="emailInput"
      :errorMessage="emailError"
      :required="required"
      name="email"
      :placeholder="emailPlaceholder"
    ></InputField>
    <!--    <SubtitleText
      v-if="won"
      :subtitle="subtitle"
      :varContents="[mainRaffleDateInfo]"
      :infoTextMarginBottomUnset="true"
    ></SubtitleText>-->
    <CheckboxWithText @checked="checkboxInput" :label="checkboxLabel"></CheckboxWithText>
    <BigButton @button-clicked="send" :text="buttonText" color="lavender"></BigButton>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import InputField from '@/components/InputField.vue';
import CheckboxWithText from '@/components/CheckboxWithText.vue';
import BigButton from '@/components/BigButton.vue';
import axios from 'axios';
import createStore from '@/store/index';
import SubtitleText from '@/components/SubtitleText.vue';
import { Prize } from '@/models/Prize';
import { adventTexts2023 } from '@/texts/theme-specific/advent-texts-2023';

export default defineComponent({
  name: 'RaffleForm',
  components: {
    InputField,
    CheckboxWithText,
    BigButton
  },
  props: {
    subtitle: String,
    won: Boolean
  },
  data() {
    return {
      required: true,
      firstName: '',
      lastName: '',
      gender: 'herr',
      email: '',
      newsletter: true,
      texts: adventTexts2023,
      mainRaffleDateInfo: adventTexts2023.infoTexts.mainRaffleDate,
      firstNamePlaceholder: adventTexts2023.formLabels.firstNamePlaceholder,
      lastNamePlaceholder: adventTexts2023.formLabels.lastNamePlaceholder,
      emailPlaceholder: adventTexts2023.formLabels.emailPlaceholder,
      buttonText: adventTexts2023.formLabels.buttonText,
      checkboxLabel: adventTexts2023.formLabels.checkboxLabel,
      firstNameError: '',
      lastNameError: '',
      emailError: '',
      emailPattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      isFirstNameValid: false,
      isLastNameValid: false,
      isEmailValid: false
    };
  },
  computed: {
    prizeId(): string | null | undefined {
      return this.myPrize?.id ? this.myPrize.id : '-';
    },
    prizeDescription(): string | null | undefined {
      return this.myPrize?.description ? this.myPrize.description : '-';
    },
    myPrize(): Prize | undefined | null {
      return createStore.state.prize;
    }
  },
  methods: {
    firstNameInput(value: string) {
      this.firstName = value;
      this.isFirstNameValid = this.checkNameValidity(value);
    },
    lastNameInput(value: string) {
      this.lastName = value;
      this.isLastNameValid = this.checkNameValidity(value);
    },
    checkboxInput(value: boolean) {
      this.newsletter = value;
    },
    emailInput(value: string) {
      this.email = value;
      this.isEmailValid = this.checkEmailValidity(value);
    },
    checkNameValidity(value: string): boolean {
      return !!value.trim();
    },
    checkEmailValidity(value: string): boolean {
      return this.emailPattern.test(value);
    },
    isValid(): boolean {
      this.firstNameError = '';
      this.emailError = '';
      this.lastNameError = '';
      if (!this.firstName.trim()) {
        this.firstNameError = this.texts.errorMessages.invalidFirstName;
      }
      if (!this.lastName.trim()) {
        this.lastNameError = this.texts.errorMessages.invalidLastName;
      }
      if (!this.emailPattern.test(this.email)) {
        this.emailError = this.texts.errorMessages.invalidEmail;
      }
      if (this.firstNameError === '' && this.emailError === '' && this.lastNameError === '') {
        return true;
      }
      return false;
    },
    async send() {
      const form = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        newsletter: this.newsletter,
        prizeId: this.prizeId,
        prizeDescription: this.prizeDescription,
        gender: this.gender
      };
      if (this.isValid()) {
        try {
          await axios.put(`${process.env.VUE_APP_BASE_ENDPOINT}/contact`, form);
          await createStore.dispatch('setHasJoinedMainRaffle', true);
        } catch (error) {
          console.log('error', error);
        }
      }
      this.$emit('send');
    }
  }
});
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
@import '~@/styles/theme.module.scss';

.raffle-form {
  .subtitle-text {
    margin-top: $padding-l;
    margin-bottom: unset;
  }
  .radio-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border: none;
    padding: 0;
    margin-bottom: 32px;
    @media (min-width: 800px) {
      justify-content: space-evenly;
    }
  }
  .radio-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    label {
      font-family: 'Proxima Nova', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $white;
      margin-right: rem(24px);
      @media (min-width: 800px) {
        margin-right: 0;
      }
    }

    input {
      appearance: none;
      margin: 0 8px 0 0;
      width: 24px;
      height: 24px;
      border: 1px solid $white;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    input::after {
      content: '';
      border-radius: 10px;
      width: 10px;
      height: 10px;
      background-color: $white;
      visibility: hidden;
    }

    input:checked::after {
      visibility: visible;
    }
  }
}
</style>
