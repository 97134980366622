
import { Options, Vue } from 'vue-class-component';
import Base from '@/views/Base.vue';
import axios from 'axios';
import { ErrorTypes } from './models/general';
import createStore from '@/store/index';
import { colors } from './configuration/themes/advent-theme-2023';

@Options({
  components: {
    Base
  }
})
export default class App extends Vue {
  isLoading = true;

  goToImpressum() {
    this.$router.push({ name: 'ImprintView' });
  }
  get showFooter(): boolean {
    return this.$route.name === 'Base';
  }

  get showOverlay(): boolean {
    return createStore.state.showOverlay;
  }

  get themeExpired() {
    return createStore.state.themeExpired;
  }

  get eventHasStarted() {
    return createStore.state.eventStarted;
  }

  get showThemeExpired() {
    return this.themeExpired || !this.eventHasStarted || this.isThemeExpiredPageVisit;
  }

  get isImpressum(): boolean {
    return this.$route.name === 'ImprintView';
  }

  get isOnLandingView(): boolean {
    return this.$route.name === 'LandingView';
  }

  get fadeLogoActivated(): boolean {
    return createStore.state.fadeOnAnimationHasStarted;
  }

  get fadeLogo(): boolean {
    return this.fadeLogoActivated && this.isOnLandingView;
  }

  get isThemeExpiredPageVisit(): boolean {
    // used to also catch manual visits and make some style changes that are otherwise
    // only applied if the theme is acutally expired
    return this.$route.fullPath === '/error?error=themeExpired';
  }

  get hideLoebLogoBackground(): boolean {
    return this.isImpressum || this.themeExpired || this.isThemeExpiredPageVisit;
  }

  get logoImage(): object {
    return require(`@/assets/pictures/logo.svg`);
  }

  get appBackgroundStyle(): object {
    let color = colors.WHITE;
    if (!this.themeExpired) {
      color = colors.LAVENDER;
    }
    return {
      backgroundColor: color,
      height: '100vh'
    };
  }
  get footerCoverStyle(): object {
    let color = colors.WHITE;
    if (!this.themeExpired) {
      color = colors.LAVENDER;
    }
    return {
      backgroundColor: color
    };
  }
  get impressumTextStyle(): object {
    if (this.showThemeExpired) {
      // since on white background, always set style to black text
      return {
        color: 'black'
      };
    }
    if (this.$route.name === 'ResultView') {
      // since on purple background, always set style to white text
      return {
        color: 'white'
      };
    }
    return {
      color: 'black'
    };
  }

  async created() {
    this.isLoading = true;
    const expirationData = await axios.get(`${process.env.VUE_APP_BASE_ENDPOINT}/expiration`);
    await createStore.dispatch('setEventStarted', expirationData.data.hasEventStarted);
    const isExpired = expirationData.data.isExpired;
    let themeIsExpired = expirationData.data.themeIsExpired;
    // themeIsExpired -> will show a much more generic, non-themed Endscreen
    const hasEventStarted = expirationData.data.hasEventStarted;
    themeIsExpired = themeIsExpired || !hasEventStarted;
    // if event has not started yet, still show generic themIsExpired screen
    // may have to be refined in the future for a separate screen/overlay
    if (!themeIsExpired) {
      const appWrapper = document.getElementById('app');
      if (appWrapper) {
        // NOTE: only sets actual color if not expired. prevents color flicker through expire overlay
        appWrapper.style.backgroundColor = colors.WHITE;
      }
    }
    if (themeIsExpired) {
      await createStore.dispatch('setThemeExpired', true);
      this.$router.push({ name: 'ErrorView', query: { error: ErrorTypes.THEME_EXPIRED } });
      // prevent scroll-y, modify colors to prevent purple flicker
      const appWrapper = document.getElementById('app');
      if (appWrapper) {
        appWrapper.style.position = 'fixed';
        appWrapper.style.top = '0';
        appWrapper.style.bottom = '0';
        appWrapper.style.left = '0';
        appWrapper.style.right = '0';
      }
    } else if (isExpired) {
      this.$router.push({ name: 'ErrorView', query: { error: ErrorTypes.EXPIRED } });
    } else {
      // since not expired or themeExpired, load prize description
      const prizeDescription = await axios.get(`${process.env.VUE_APP_BASE_ENDPOINT}/prize/prize-description-today`);
      await createStore.dispatch('setPrizeDescription', prizeDescription.data);
    }
    this.isLoading = false;
  }

  updateAppWrapperBackground() {
    const appWrapper = document.getElementById('app');
    // set appWrapper background to white if is impressum
    // -> prevents visual issues for devices that need to scroll in impressum
    if (appWrapper) {
      if (this.isImpressum || this.isThemeExpiredPageVisit) {
        appWrapper.style.backgroundColor = colors.WHITE;
      } else {
        appWrapper.style.backgroundColor = colors.LAVENDER;
      }
    }
  }

  updated() {
    // TODO: on routeChange or something, there has to be a better way
    // couldn't get the watcher to work somehow, hence: https://stackoverflow.com/a/56325120
    this.updateAppWrapperBackground();
  }
}
