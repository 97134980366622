
import { defineComponent } from 'vue';
import createStore from '@/store/index';
import { Prize } from '@/models/Prize';
import Title from '@/components/Title.vue';
import TitleWithImage from '@/components/TitleWithImage.vue';
import SubtitleText from '@/components/SubtitleText.vue';
import RaffleForm from '@/components/RaffleForm.vue';
import { Participated } from '@/models/general';
import { getPriceImage } from '@/services/imageResolverService';
import { getCurrentTexts } from '@/texts/textService';

export default defineComponent({
  name: 'ResultsView',
  components: {
    Title,
    TitleWithImage,
    SubtitleText,
    RaffleForm
  },
  data() {
    return {
      hasTriedToday: false,
      texts: getCurrentTexts(),
      subtitle1: getCurrentTexts().subTitles.redeem,
      subtitle2: getCurrentTexts().subTitles.mainRaffle,
      participatedEnum: Participated,
      wonInfo: getCurrentTexts().infoTexts.won,
      mainRaffleInfo: getCurrentTexts().infoTexts.mainRaffle,
      mainRaffleDateInfo: getCurrentTexts().infoTexts.mainRaffleDate,
      titleWithImageTitle: getCurrentTexts().titleWithImageTexts.participated,
      mainPrizeText: getCurrentTexts().titleWithImageTexts.mainPrize,
      hasJoinedMainRaffleInfo: getCurrentTexts().infoTexts.hasJoinedMainRaffle,
      hasJoinedMainRaffleInfoAfterWin: getCurrentTexts().infoTexts.hasJoinedMainRaffleAfterWin,

      showScrollIcon: true
    };
  },
  watch: {
    hasJoinedMainRaffle(newVal) {
      if (newVal) {
        console.log('scaling');
        this.scaleAssets();
      }
    }
  },
  methods: {
    moveDown(): void {
      document.getElementById('scrollTarget')?.scrollIntoView({ behavior: 'smooth' });
      this.showScrollIcon = false;
    },
    scrollHandler(): void {
      setTimeout(() => {
        if (this.showScrollIcon) {
          this.showScrollIcon = false;
        }
      }, 300);
    },
    scaleAssets() {
      this.scaleDesktopAsset();
      this.scaleFooterColorCover();
    },
    scaleDesktopAsset() {
      // grab logo position
      const logoElement = document.querySelector('#logo') as HTMLElement;
      if (!logoElement) {
        return;
      }
      const logoCoordinates = logoElement.getBoundingClientRect();
      // grab start of 'other-details'
      const otherDetailsElement = document.querySelector('.other-details') as HTMLElement;
      if (!otherDetailsElement) {
        return;
      }
      const otherDetailsCoordinates = otherDetailsElement.getBoundingClientRect();
      // height of the asset is space between otherDetails and logo
      const assetHeight = `${otherDetailsCoordinates.top - logoCoordinates.bottom}px`;
      // grab assetcontainer and set height
      const assetContainer = document.querySelector('#asset-container-desktop') as HTMLElement;
      if (assetContainer) {
        assetContainer.style.height = assetHeight;
      }
    },
    scaleFooterColorCover() {
      // grab other Details Element (since we want to cover potential gaps between it and the bottom of the page
      const otherDetailsElement = document.querySelector('.other-details') as HTMLElement;
      if (!otherDetailsElement) {
        return;
      }
      const otherDetailsCoordinates = otherDetailsElement.getBoundingClientRect();
      // grab available screen height
      const baseElement = document.querySelector('#base') as HTMLElement;
      if (!baseElement) {
        return;
      }
      const baseCoordinates = baseElement.getBoundingClientRect();
      const availableHeight = baseCoordinates.height;
      const someMargin = 30;
      // calculate height so it leaves no gap but also does not go higher than the start of the 'other details' section
      const calculatedHeight = availableHeight - otherDetailsCoordinates.top - someMargin;
      const min = 150;
      const coverHeight = `${Math.max(min, calculatedHeight)}px`;

      const footerColorCoverElement = document.querySelector('.footer-color-cover') as HTMLElement;
      if (footerColorCoverElement) {
        footerColorCoverElement.style.height = coverHeight;
      }
    }
  },
  async created() {
    document.getElementById('app')?.addEventListener('scroll', this.scrollHandler);
    window.addEventListener('resize', () => {
      setTimeout(() => {
        this.scaleAssets();
      }, 200);
    });

    await createStore.dispatch('setShowOverlay', false);
    if (this.hasParticipated) {
      await createStore.dispatch('setParticipated', Participated.PARTICIPATED_TODAY);
    }
    if (this.participated === Participated.NOT_PARTICIPATED) {
      this.$router.push({ name: 'LandingView' });
    }
  },
  mounted() {
    this.scaleAssets();
  },
  beforeUnmount() {
    document.getElementById('app')?.removeEventListener('scroll', this.scrollHandler);
  },
  computed: {
    myPrize(): Prize | undefined {
      return createStore.state.prize;
    },
    participated(): Participated {
      return createStore.state.participated;
    },
    wonPrizeShowImage(): boolean {
      return this.participated === Participated.WON;
    },
    mainPrizeShowImage(): boolean {
      // show main price image if participated but not won
      return this.participated === Participated.PARTICIPATED_TODAY || this.participated === Participated.LOST;
    },
    currentPriceImage(): string {
      const imageCode = this.myPrize ? this.myPrize.code : '';
      return getPriceImage(imageCode);
    },
    birdAssetMobile(): string {
      return require('@/assets/pictures/advent_birds_mobile.svg');
    },
    birdAssetDesktopLeft(): string {
      return require('@/assets/pictures/advent_birds_desktop_left.svg');
    },
    birdAssetDesktopRight(): string {
      return require('@/assets/pictures/advent_birds_desktop_right.svg');
    },
    hasParticipated(): boolean {
      return (
        this.participated !== Participated.WON &&
        this.participated !== Participated.LOST &&
        document.cookie === 'participated=true'
      );
    },
    title(): string {
      switch (this.participated) {
        case Participated.WON: {
          return this.texts.titles.won;
        }
        case Participated.LOST: {
          return this.texts.titles.lost;
        }
        case Participated.PARTICIPATED_TODAY: {
          return this.texts.titles.participated;
        }
        default: {
          return '';
        }
      }
    },
    titleText(): string {
      console.log(this.participated);
      switch (this.participated) {
        case Participated.WON: {
          return this.texts.titleTexts.won;
        }
        case Participated.LOST: {
          return this.texts.titleTexts.lost;
        }
        case Participated.PARTICIPATED_TODAY: {
          return this.texts.titleTexts.participated;
        }
        default: {
          return '';
        }
      }
    },

    hasJoinedMainRaffle(): boolean {
      return createStore.state.hasJoinedMainRaffle;
    },
    userHasWon(): boolean {
      return this.participated === Participated.WON;
    },
    // styles - have to be inline to work with the way themes are set up
    chevronImage(): object {
      return require(`@/assets/pictures/chevron_easter23.svg`);
    },
    chevronStyle(): object {
      return {
        backgroundImage: `url('${this.chevronImage}')`
      };
    }
  }
});
