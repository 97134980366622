
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SubtitleText',
  props: {
    subtitle: String,
    isImpressum: {
      type: Boolean,
      default: false
    },
    varContents: {
      type: Array,
      default: () => []
    },
    infoTextMarginBottomUnset: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  }
});
