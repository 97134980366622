
import { defineComponent } from 'vue';
import { getPriceImage } from '@/services/imageResolverService';

export default defineComponent({
  name: 'TitleWithImage',
  props: {
    title: String,
    titleText: String,
    showImage: Boolean
  },
  data() {
    return {};
  },
  computed: {
    // styles - have to be inline to work with the way themes are set up
    placeholderImage(): string {
      return getPriceImage('main');
    },
    mainPrizeImageStyle(): object {
      return {
        backgroundImage: `url('${this.placeholderImage}')`
      };
    }
  }
});
