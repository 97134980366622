<template>
  <div class="base" :class="{ 'not-expired': !themeExpired }" :style="baseBackgroundStyle" id="base">
    <div class="base-view">
      <router-view class="base-router" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import axios from 'axios';
import createStore from '@/store/index';
import { Prize } from '@/models/Prize';
import { colors } from '@/configuration/themes/advent-theme-2023';

export default defineComponent({
  name: 'Base',
  components: {},
  data() {
    return {
      hasTriedToday: false,
      prize: undefined
    };
  },
  methods: {
    async getPrize() {
      const expires = new Date();
      expires.setHours(23, 59, 59, 999);
      const prize = await axios.get(`${process.env.VUE_APP_BASE_ENDPOINT}/prizewon`, {
        headers: {
          'Set-cookies': document.cookie
        }
      });
      await createStore.dispatch('setPrize', prize.data);
      document.cookie = `hasTriedToday=true; expires = ${expires} ;path=/`;
      this.hasTriedToday = true;
    }
  },
  created() {
    document.cookie === 'hasTriedToday=true' ? (this.hasTriedToday = true) : (this.hasTriedToday = false);
  },
  computed: {
    myPrize(): Prize | undefined {
      return createStore.state.prize;
    },
    themeExpired(): boolean {
      return createStore.state.themeExpired;
    },
    baseBackgroundStyle(): object {
      /*
       we only set to color once this.themeExpired has evaluated to true, prevents
       flickering of color->white when theme is actually expired
       */
      return !this.themeExpired ? { backgroundColor: 'transparent' } : { backgroundColor: colors.WHITE };
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '~@/styles/global.scss';
@import '~@/styles/variables.scss';

.base {
  //padding-bottom: rem(85px);
  min-height: rem(calc(100vh - 85px));
  display: flex;
  flex-direction: row;
  z-index: 1;
  &.not-expired {
  }
  .base-view {
    width: 100%;
    padding-top: rem(70px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    position: relative;
    z-index: 7;

    .base-router {
      z-index: 3;
      height: 100%;
    }
  }
  h1 {
    font-size: rem(32px);
  }
}
</style>
