<template>
  <div class="check-box">
    <label class="container">
      <input
        checked
        class="check"
        required="required"
        type="checkbox"
        name="id"
        @click="check"
        id="checkbox"
        v-model="value"
      />

      <span class="checkmark"></span>
    </label>
    <p>{{ label }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CheckBox',
  props: {
    label: String
  },
  data() {
    return {
      isValid: Boolean,
      inputElement: { type: Object as () => HTMLInputElement },
      value: ''
    };
  },
  computed: {},
  methods: {
    check() {
      const checkbox = document.getElementById('checkbox') as HTMLInputElement;
      this.$emit('checked', checkbox.checked);
    }
  }
});
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
@import '~@/styles/variables.scss';
@import '~@/styles/theme.module.scss';

.check-box {
  display: flex;
  padding-top: rem(30px);
  padding-bottom: rem(17px);
  .container {
    display: flex;
    position: relative;
    margin-right: $padding-s;
    margin-bottom: rem(12px);
    cursor: pointer;
    font-size: rem(22px);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  p {
    margin-left: rem(16px);
    line-height: 1.33;
    color: $white;
  }
  /* Hide the browser's default checkbox */
  .container input {
    //   position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    padding-right: rem(9px);
  }

  .checkmark {
    position: absolute;
    top: rem(2px);
    left: 0;
    height: rem(24px);
    width: rem(24px);
    border-radius: 4px;
    background-color: $lower-background-color;
    border: 1px solid $white;
  }

  .container:hover input ~ .checkmark {
  }

  .container input:checked ~ .checkmark {
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmark:after {
    display: block;
  }

  .container .checkmark:after {
    left: rem(10px);
    top: rem(5px);
    width: rem(4px);
    height: rem(9px);
    border: solid $white;
    border-width: 0 rem(1px) rem(1px) 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
</style>
