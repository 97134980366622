import { Participated } from '@/models/general';
import { createStore } from 'vuex';
export default createStore({
  state: {
    prize: undefined,
    prizeDescription: undefined,
    participated: Participated.NOT_PARTICIPATED,
    hasJoinedMainRaffle: false,
    showOverlay: false,
    themeExpired: false,
    eventStarted: false,
    fadeOnAnimationHasStarted: false
  },
  mutations: {
    setPrize(state, prize) {
      state.prize = prize;
    },
    setPrizeDescription(state, prizeDescription) {
      state.prizeDescription = prizeDescription;
    },
    setParticipated(state, participated: Participated) {
      state.participated = participated;
    },
    setHasJoinedMainRaffle(state, hasJoined: boolean) {
      state.hasJoinedMainRaffle = hasJoined;
    },
    setShowOverlay(state, value: boolean) {
      state.showOverlay = value;
    },
    setThemeExpired(state, value: boolean) {
      state.themeExpired = value;
    },
    setEventStarted(state, value: boolean) {
      state.eventStarted = value;
    },
    setFadeOnAnimationHasStarted(state, value: boolean) {
      state.fadeOnAnimationHasStarted = value;
    }
  },
  actions: {
    setPrize(context, prize) {
      context.commit('setPrize', prize);
    },
    setPrizeDescription(context, prizeDescription) {
      context.commit('setPrizeDescription', prizeDescription);
    },
    setParticipated(context, participated: Participated) {
      context.commit('setParticipated', participated);
    },
    setHasJoinedMainRaffle(context, hasJoined: boolean) {
      context.commit('setHasJoinedMainRaffle', hasJoined);
    },
    setShowOverlay(context, value: boolean) {
      context.commit('setShowOverlay', value);
    },
    setThemeExpired(context, value: boolean) {
      context.commit('setThemeExpired', value);
    },
    setEventStarted(context, value: boolean) {
      context.commit('setEventStarted', value);
    },
    setFadeOnAnimationHasStarted(context, value: boolean) {
      context.commit('setFadeOnAnimationHasStarted', value);
    }
  },
  modules: {}
});
