<template>
  <div class="title-with-image" :class="{ 'with-image': !showImage }">
    <h1>
      {{ title }}
    </h1>
    <div v-if="showImage" class="image" :style="mainPrizeImageStyle"></div>
    <p>{{ titleText }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { getPriceImage } from '@/services/imageResolverService';

export default defineComponent({
  name: 'TitleWithImage',
  props: {
    title: String,
    titleText: String,
    showImage: Boolean
  },
  data() {
    return {};
  },
  computed: {
    // styles - have to be inline to work with the way themes are set up
    placeholderImage(): string {
      return getPriceImage('main');
    },
    mainPrizeImageStyle(): object {
      return {
        backgroundImage: `url('${this.placeholderImage}')`
      };
    }
  }
});
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
@import '~@/styles/theme.module.scss';

.title-with-image {
  text-align: center;
  color: $main-color;
  h1 {
    margin: 0;
  }
  &.with-image {
    h1 {
      margin-bottom: rem(24px);
    }
  }
  .image {
    width: rem(180px);
    height: rem(180px);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin: rem(20px) auto;
  }
  p {
    color: $main-color;
  }
}
</style>
