import { TextScheme } from '@/models/Texts';
import {
  getDefaultTitles,
  getDefaultSubTitles,
  getDefaultTitleTexts,
  getDefaultTitleWithImageTexts,
  getDefaultImprint,
  getDefaultInfoTexts,
  getDefaultFormLabels,
  getDefaultButtonLabels,
  getDefaultErrorMessages,
  getDefaultCouponTexts
} from '@/texts/textService';

/*
Some (Most) of the texts have not changed since the last event. That last event (easter 2020)
is what the defaults are based on, hence grab defaults and only overwrite where needed.
 */

export const adventTexts2022: TextScheme = {
  titles: {
    ...getDefaultTitles(),
    landingView: 'Tippen Sie auf das Päckli um zu spielen'
  },
  subTitles: { ...getDefaultSubTitles(), redeem: 'Ihre Kontaktdaten' },
  titleTexts: {
    ...getDefaultTitleTexts(),
    hasJoinedMainRaffle:
      'Vielen Dank für Ihre Teilnahme an der Hauptverlosung. Die Ziehung der Gewinnerin oder des Gewinners findet zwischen dem 25.12. und 31.12. 2022 statt.'
  },
  titleWithImageTexts: {
    ...getDefaultTitleWithImageTexts(),
    mainPrize: `Einkaufsgutschein LOEB im Wert von 1'000.- CHF`
  },
  imprint: {
    ...getDefaultImprint(),
    copyright: `<p><span class="bold-span">© 2022 Loeb AG</span>`
  },
  infoTexts: {
    ...getDefaultInfoTexts(),
    mainRaffleDate: `<p>
  Die Ziehung der Gewinnerin oder des Gewinners findet zwischen dem 25.12. und 31.12.2022 statt.
</p>`,
    won: `<p>Bitte hinterlegen Sie Ihre Angaben, damit wir Sie kontaktieren können. Den Gewinn senden wir Ihnen gerne per Post zu.
Sie nehmen automatisch an der Verlosung vom Hauptgewinn teil.</p>`
  },
  formLabels: getDefaultFormLabels(),
  buttons: getDefaultButtonLabels(),
  errorMessages: {
    ...getDefaultErrorMessages(),
    expired: 'Das Loeb Adventsgame ist leider vorbei. Wir freuen uns bereits jetzt aufs nächste Jahr.'
  },
  couponTexts: getDefaultCouponTexts()
};
