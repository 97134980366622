<template>
  <div class="imprint-view">
    <div class="close" @click="close()"></div>
    <div :subtitle="subtitle" :isImpressum="true">
      <div v-html="title"></div>
      <div v-html="editing"></div>
      <div v-html="design"></div>
      <div v-html="legal"></div>
      <div v-html="copyright"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { adventTexts2023 } from '@/texts/theme-specific/advent-texts-2023';

export default defineComponent({
  name: 'ImprintView',
  components: {},
  data() {
    return {
      prize: undefined,
      title: adventTexts2023.imprint.title,
      editing: adventTexts2023.imprint.editing,
      design: adventTexts2023.imprint.design,
      legal: adventTexts2023.imprint.legal,
      copyright: adventTexts2023.imprint.copyright,
      subtitle: 'Impressum'
    };
  },
  methods: {
    close() {
      if (window.history.length > 2) {
        this.$router.go(-1);
      } else {
        console.log('pushing to base');
        this.$router.push('/');
      }
    },
    resetScrollPosition() {
      document.getElementById('app')?.scrollTo(0, 0);
    }
  },
  created() {
    this.resetScrollPosition();
  }
});
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
@import '~@/styles/variables.scss';

.imprint-view {
  min-height: rem(calc(100vh - #{$imprint-view-padding-top} - #{$imprint-view-padding-bottom}));
  padding: $imprint-view-padding-top $imprint-view-padding-sides $imprint-view-padding-bottom;
  background-color: $white;

  position: relative;
  z-index: 10;

  .close {
    z-index: 1000;
    width: rem(16px);
    height: rem(16px);
    background-image: url('~@/assets/pictures/close.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    top: rem(48px);
    right: rem(24px);
    display: flex;
    position: absolute;
    cursor: pointer;
  }
}
</style>
