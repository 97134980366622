<template>
  <div class="error-view-wrapper">
    <!--    if theme not expired-->
    <div class="error-view" v-if="!themeExpired">
      <Title :title="title" class="error-title"></Title>
      <div class="image" :style="{ backgroundImage: `url('${errorImage}')` }"></div>
      <div class="warning-text">
        <p v-html="errorMessage" />
      </div>
      <div class="instructions" v-if="false">
        <p v-html="locationInfo"></p>
        <div v-html="locationInstructions"></div>
      </div>
    </div>

    <!--  if theme expired -> simply overlay entire app-->
    <div class="non-themed-overlay" v-else>
      <div class="content">
        <div class="overlay-title">
          {{ title }}
        </div>
        <div class="overlay-warning-text">
          <p v-html="errorMessage"></p>
        </div>
        <div class="button-container">
          <BigButton
            color="purple-dark"
            :is-themed="false"
            :text="buttonText"
            @button-clicked="openLoebMainPage"
          ></BigButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import createStore from '@/store/index';
import Title from '@/components/Title.vue';
import { ErrorTypes } from '@/models/general';
import BigButton from '@/components/BigButton.vue';
import { getCurrentTexts } from '@/texts/textService';

export default defineComponent({
  name: 'ErrorView',
  components: {
    Title,
    BigButton
  },
  data() {
    return {
      // not sure why this one text is hard-coded, but I'm gonna leave it here for now.
      subtitle2: 'Hauptverlosung',
      texts: getCurrentTexts(),
      mainRaffleInfo: getCurrentTexts().infoTexts.mainRaffle,
      mainRaffleDateInfo: getCurrentTexts().infoTexts.mainRaffleDate,
      titleWithImageTitle: getCurrentTexts().titleWithImageTexts.participated,
      hasJoinedMainRaffleInfo: getCurrentTexts().infoTexts.hasJoinedMainRaffle,
      farFromWindowMessage: getCurrentTexts().errorMessages.farFromWindow,
      locationInfo: getCurrentTexts().errorMessages.locationInfo,
      locationInstructions: getCurrentTexts().errorMessages.locationInstructions,
      buttonText: getCurrentTexts().buttons.stayInformed
    };
  },
  methods: {
    showInstructions(): boolean {
      // NOTE: is a non-issue since the location thing is no longer being utilized
      return this.errorType === ErrorTypes.LOCATION_DISABLED || this.errorType === ErrorTypes.TOO_FAR;
    },
    openLoebMainPage() {
      window.open('https://loeb.ch/', '_blank');
    }
  },
  computed: {
    hasJoinedMainRaffle(): boolean {
      return createStore.state.hasJoinedMainRaffle;
    },
    errorType(): string {
      const errorType = this.$route.query.error;
      return errorType as string;
    },
    errorImage(): object {
      return require('@/assets/pictures/circleAdventPhrase.png');
    },
    themeExpired(): boolean {
      return this.errorType === ErrorTypes.THEME_EXPIRED;
    },
    title(): string {
      switch (this.errorType) {
        case ErrorTypes.EXPIRED: {
          return this.texts.titles.expired;
        }
        case ErrorTypes.THEME_EXPIRED: {
          return this.texts.titles.themeExpired;
        }
        default: {
          return this.texts.titles.defaultError;
        }
      }
    },
    errorMessage(): string {
      switch (this.errorType) {
        case ErrorTypes.EXPIRED: {
          return this.texts.errorMessages.expired;
        }
        case ErrorTypes.THEME_EXPIRED: {
          return this.texts.errorMessages.themeExpired;
        }
        case ErrorTypes.LOCATION_DISABLED: {
          return this.texts.errorMessages.locationDisabled;
        }
        case ErrorTypes.TOO_FAR: {
          return this.texts.errorMessages.farFromWindow;
        }
        default: {
          return '';
        }
      }
    }
  }
});
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
@import '~@/styles/variables.scss';
@import '~@/styles/theme.module.scss';

.error-view {
  //padding: $error-view-padding-top $error-view-padding-sides $error-view-padding-bottom;
  background-color: transparent;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  z-index: 1;
  .error-title {
    padding-top: 0;
    @media (min-width: 800px) {
      padding-top: 8rem;
    }
    padding-top: 35%;
    @include desktop() {
      padding-top: 10%;
    }
    @include tablet-portrait() {
      padding-top: 30%;
    }
    @include tablet-landscape() {
      padding-top: 10%;
    }
    @include phone-portrait() {
      padding-top: 30%;
    }
    @include phone-landscape() {
      padding-top: 10%;
    }
    @include small-phone-portrait() {
      padding-top: 30%;
      width: 100%;
    }
    @include small-phone-landscape() {
      padding-top: 20%;
    }

    position: relative;
    z-index: 30;
  }
  .warning-text {
    margin: 6rem rem(24px) rem(60px);
    height: rem(9rem);
    width: 80%;
    text-align: center;
    position: relative;
    z-index: 30;
    display: flex;
    justify-content: center;
    background-color: transparent;

    @include phone-portrait() {
      margin: 3rem rem(0px) rem(0px);
    }
    @include phone-landscape() {
      margin: 0rem rem(0px) rem(0px);
      height: rem(5rem);
    }
    @include tablet-portrait() {
      padding-top: 10%;
    }
    @include small-phone-portrait() {
      margin: 0 rem(0px) rem(0px);
    }
    @include small-phone-landscape() {
      margin: 0 rem(24px) rem(45px);
    }

    p {
      width: 450px;
      margin-top: rem(40px);
    }

    @media (min-width: 800px) {
      margin: 0 rem(24px) 0;
    }
  }
  .instructions {
    position: relative;
    z-index: 30;
    > p {
      color: $white;
    }
  }

  .image {
    height: 386px;
    width: 400px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    left: 0;
    position: relative;
    bottom: 0;
    transform: rotate(0) translate(0, 0);

    @include phone-portrait() {
      height: 300px;
    }
    @include phone-landscape() {
      height: 250px;
    }
    @include small-phone-portrait() {
      height: 200px;
    }
    @include small-phone-landscape() {
      height: 200px;
    }
  }
}
.non-themed-overlay {
  position: fixed;
  z-index: 50;
  left: 0;
  right: 0;
  top: 0;
  height: 80vh;
  background-color: $white;

  .content {
    position: absolute;
    min-height: 60%;
    top: 25%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .overlay-title {
      font-family: Helvetica;
      font-size: 21px;
      font-weight: normal;
      text-align: center;
      //position: relative;
      margin: rem(5px) rem(80px) 0;
    }

    .overlay-warning-text {
      margin: rem(38px) rem(24px) rem(36px);
      text-align: center;
      //position: relative;
      max-width: rem(330px);
      align-self: center;
      //z-index: 30;
      > p {
        color: $black;
      }
    }

    .button-container {
      margin: 0 rem(24px) 0;
      //position: relative;
      max-width: rem(330px);
      align-self: center;

      ::v-deep(.big-button) {
        .purple-dark {
          padding-left: rem(20px);
          padding-right: rem(20px);
        }
      }
    }
  }
}
.error-title {
  width: 80%;
}
</style>
