<template>
  <div class="big-button">
    <button class="black" :class="colorClass" :disabled="disabled" @click="emitClicked">
      <p :class="{ 'disabled-button': disabled }">{{ text }}</p>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BigButton',
  props: {
    text: String,
    disabled: Boolean,
    isThemed: {
      /*
      if isThemed, the dynamic inline styles are applied which use the colors
      from the currentTheme. otherwise, the colorClass (based on this.color) is used
      */
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'black'
    }
  },
  data() {
    return {};
  },
  methods: {
    emitClicked() {
      this.$emit('button-clicked');
    }
  },
  computed: {
    colorClass(): object {
      return {
        [`${this.color}`]: true
      };
    }
  }
});
</script>

<style scoped lang="scss">
@import '~@/styles/global.scss';
@import '~@/styles/variables.scss';

.big-button {
  padding-top: $padding-xs;
  padding-bottom: $padding-xs;
  button {
    width: 100%;
    height: rem(48px);
    border-radius: rem(23.5px);
    cursor: pointer;
    p {
      font-weight: bold;
      color: $white;
      margin: 0;
    }
  }
  .golden {
    border: solid rem(0.5px) $golden;
    background-color: $golden;
  }
  .black {
    background-color: $black;
    border: solid rem(0.5px) $black;
  }
  .purple-dark {
    background-color: $purple-dark;
    border: solid rem(0.5px) $purple-dark;
  }
  .lavender {
    background-color: $lavender;
    border: solid rem(0.5px) $purple-dark;
    p {
      color: $main-color;
      font-weight: 500;
    }
  }
  .green-dark {
    background-color: $dark-green;
    border: solid rem(0.5px) $dark-green;
  }
  .white {
    background-color: $white;
    border: solid rem(0.5px) $white;

    p {
      color: $loeb-blue;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .disabled-button {
    color: #686868;
  }
}
</style>
